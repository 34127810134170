import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';

import { alpha, Grid, Typography, withStyles } from '@material-ui/core';
import { GoogleMap, LoadScriptNext, Polygon } from '@react-google-maps/api';

import { clientData } from '../../../utils/clients';
import { getImage } from '../../../utils/reportHelpers';
import { darkenColor } from '../../../utils/helpers';

import ByDateReport from './ByDateLine';
import ByTimeReport from './ByTimeArea';
import ByTimeAllReport from './ByTimeAreaAll';
import ByDayOfWeekReport from './DayOfWeekBar';
import ByDayHourlyTable from './HourlyByDayTable';
import MonthlyAveragePie from './MonthlyAveragePie';

const styles = ({
  highlights: {
    fontFamily: 'Nunito Sans',
    fontSize: 14,
    fontWeight: 400,
    width: '100%',
    maxHeight: 190,
    overflow: 'hidden',
    paddingLeft: 0,
    whiteSpace: 'pre-wrap',
    '&:focus': {
      paddingLeft: 2, // needed to make cursor visible on chrome at start of newline
    },
  },
  highlightsExtra: {
    fontFamily: 'Nunito Sans',
    fontSize: 12,
    fontWeight: 400,
    width: '100%',
    maxHeight: 190,
    overflow: 'hidden',
    marginTop: -15,
    paddingLeft: 0,
    whiteSpace: 'pre-wrap',
    '&:focus': {
      paddingLeft: 2, // needed to make cursor visible on chrome at start of newline
    },
  },
});


class MultiLocationReport extends PureComponent {

  render() {
    const {
      byDateChartData, byDateData, byTimeChartData, byTimeData, change, chartColors, classes, client, dateLabels, detailed, isFlagship, isPuppeteer, locationLabels, mapRegions,
      pageHeight, pageWidth, reportByWeek, reportClient, reportDateInterval, reportLocations, reportHighlights, reportSubTitle, reportTitle, showDemoArray } = this.props;

    return (
      <>
        <LoadScriptNext
          googleMapsApiKey='AIzaSyDgSY4KoPydGmm1NYoyeIQfAa-aShZAAds'
          mapIds={['10324736f3dd8795']}
        >
          <Grid container direction='column' justifyContent='center' alignItems='center' style={{ width: pageWidth, height: pageHeight, backgroundColor: 'white' }}>
            <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight * 0.9545, marginTop: pageWidth / 32, marginBottom: pageWidth / 32, backgroundColor: 'white' }}>

              {/* HEADER */}
              <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight / 22 }}>
                <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '65%' }}>
                  <Grid item container style={{ width: '100%' }}>
                    <Typography style={{ marginTop: -7, fontWeight: 700, fontSize: 30, fontFamily: 'Rajdhani' }}>{this.props.auth.user.email.includes('absre') ? '200 Park Ave South' : isFlagship ? '5th Ave Flagship Study' : reportTitle}</Typography>
                    <Typography style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 20, fontWeight: 700, fontSize: 14, fontFamily: 'Nunito Sans' }}>{this.props.auth.user.email.includes('absre') ? '' : reportSubTitle}</Typography>
                  </Grid>
                  <Typography style={{ marginTop: -7, fontWeight: 500, fontSize: 18, fontFamily: 'Rajdhani' }}>{dateLabels.mainHeader}</Typography>
                </Grid>
                <Grid item container direction='row' justifyContent='flex-end' alignItems='flex-start' style={{ height: '100%', width: '35%' }}>
                  <img style={{ height: '100%' }} alt='Client Logo' src={this.props.auth.user.email.includes('absre') ? getImage('usp/absreLogo.png', false) : getImage(clientData[reportClient].reports.logo, false)} />
                </Grid>
              </Grid>

              {/* LOCATIONS HEADER */}
              <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: pageHeight / 50, width: '100%', flexWrap: 'nowrap', }}>

                {reportLocations.map((s, idx) => (
                  [
                    <Grid key={idx} item container direction='column' justifyContent='space-between' alignItems='stretch' style={{ width: parseInt((100 / reportLocations.length) - 1).toString() + '.' + Math.max(Math.min(reportLocations.length - 2, 9), 0).toString() + '%', height: '100%' }}>

                      {!mapRegions[idx].color &&
                        <img style={{ height: pageHeight * .15, width: '100%', objectFit: 'cover' }} alt='Location Map' src={getImage(client + '/' + locationLabels[idx].replace('ä', 'a').replaceAll(/[\s\+\']+/g, '_') + '.jpg', false)} />
                      }

                      {mapRegions[idx].color &&
                        <div
                          style={{
                            width: '100%',
                            // height: '100%',
                            height: '168px',
                            overflow: 'hidden',
                            position: 'relative',
                            clipPath: 'inset(0 0 0 0)',
                          }}
                        >
                          <GoogleMap
                            mapContainerStyle={{ width: '100%', height: '110%' }}
                            options={{
                              mapId: '10324736f3dd8795',
                              mapTypeControl: false,
                              streetViewControl: false,
                              fullscreenControl: false,
                              zoomControl: false, // Disable zoom control
                              rotateControl: false, // Disable rotate control
                              scaleControl: false, // Disable scale control
                              panControl: false, // Disable pan control
                              disableDefaultUI: true, // Disable all default UI controls
                            }}
                            zoom={17.0}
                            center={mapRegions[idx].position}
                          >
                            <Polygon
                              paths={mapRegions[idx].polygonCoordinates}
                              options={{
                                fillColor: mapRegions[idx].color,
                                fillOpacity: 0.3,
                                strokeColor: darkenColor(mapRegions[idx].color, 30),
                                strokeWeight: 3,
                                strokeOpacity: 0.9,
                                zIndex: 1
                              }}
                            />
                          </GoogleMap>
                        </div>
                      }

                      <Grid item container direction='row' justifyContent='center' alignItems='center' style={{ width: '100%', height: pageHeight / 30, marginTop: pageHeight / 250, backgroundColor: alpha(chartColors[idx], 0.6) }}>
                        <Typography noWrap
                          style={locationLabels[idx].length < 28
                            // fontSize was 13 for mcdonalds to fit name in box, can try counting characters in location string to dynamically change font size
                            ? { marginTop: 0, fontWeight: 700, fontFamily: 'Nunito Sans', fontSize: Math.max(18 - reportLocations.length, 5) }
                            : { marginTop: 0, fontWeight: 700, fontFamily: 'Nunito Sans', fontSize: Math.max(15 - reportLocations.length, 5), lineHeight: 1, textAlign: 'center' }
                          }>
                          {locationLabels[idx]}
                        </Typography>
                      </Grid>
                      <Grid container item direction='row' justifyContent='center' alignItems='stretch' style={{ overflow: 'hidden', width: '100%', height: (reportClient === 'lee' || isFlagship || !change) ? pageHeight / 15 : pageHeight / 12, marginTop: pageHeight / 250 }}>
                        <Grid item xs container direction='row' justifyContent='center' alignItems='center' style={{ minWidth: 59, backgroundColor: '#efefefff', height: '100%' }}>
                          <Grid item style={{ display: 'block', paddingBottom: 2 }}>
                            <Typography style={{ paddingLeft: 0, marginTop: 0, fontWeight: 700, fontSize: Math.max(22 - reportLocations.length, 12), fontFamily: 'Nunito Sans' }}>{byDateData[idx].avgsFormatted.dailyAvg}&thinsp;<small>{(byDateData[idx].avgs.dailyAvg >= 1000) ? 'K' : ''}</small></Typography>
                            <Typography style={{ paddingLeft: 0, marginTop: -3, fontWeight: 400, fontSize: Math.max(12 - reportLocations.length, 6), fontFamily: 'Nunito Sans' }}>Daily Avg</Typography>
                            <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 0, marginTop: 1, fontWeight: 600, fontSize: Math.max(20 - reportLocations.length, 10), fontFamily: 'Nunito Sans', color: (byDateData[idx].perChange.dailyAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(byDateData[idx].perChange.dailyAvg >= 0) ? '+' : ''}{(byDateData[idx].perChange.dailyAvg).toFixed(0)}<small>%</small></Typography>
                            <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 0, marginTop: -5, fontWeight: 400, fontSize: Math.max(11 - reportLocations.length, 5), fontFamily: 'Nunito Sans' }}>vs. prev {dateLabels.abr}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs container direction='row' justifyContent='center' alignItems='center' style={{ minWidth: 59, height: '100%', backgroundColor: '#efefefff', marginLeft: 4 }}>
                          <Grid item style={{ display: 'block', paddingBottom: 2 }}>
                            <Typography style={{ paddingLeft: 0, marginTop: 0, fontWeight: 700, fontSize: Math.max(22 - reportLocations.length, 12), fontFamily: 'Nunito Sans' }}>{byDateData[idx].avgsFormatted.wkdayAvg}&thinsp;<small>{(byDateData[idx].avgs.wkdayAvg >= 1000) ? 'K' : ''}</small></Typography>
                            <Typography style={{ paddingLeft: 0, marginTop: -3, fontWeight: 400, fontSize: Math.max(12 - reportLocations.length, 6), fontFamily: 'Nunito Sans' }}>Wkday Avg</Typography>
                            <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 0, marginTop: 1, fontWeight: 600, fontSize: Math.max(20 - reportLocations.length, 10), fontFamily: 'Nunito Sans', color: (byDateData[idx].perChange.wkdayAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(byDateData[idx].perChange.wkdayAvg >= 0) ? '+' : ''}{(byDateData[idx].perChange.wkdayAvg).toFixed(0)}<small>%</small></Typography>
                            <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 0, marginTop: -5, fontWeight: 400, fontSize: Math.max(11 - reportLocations.length, 5), fontFamily: 'Nunito Sans' }}>vs. prev {dateLabels.abr}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs container direction='row' justifyContent='center' alignItems='center' style={{ minWidth: 59, height: '100%', backgroundColor: '#efefefff', marginLeft: 4 }}>
                          <Grid item style={{ display: 'block', paddingBottom: 2 }}>
                            <Typography style={{ paddingLeft: 0, marginTop: 0, fontWeight: 700, fontSize: Math.max(22 - reportLocations.length, 12), fontFamily: 'Nunito Sans' }}>{byDateData[idx].avgsFormatted.wkendAvg}&thinsp;<small>{(byDateData[idx].avgs.wkendAvg >= 1000) ? 'K' : ''}</small></Typography>
                            <Typography style={{ paddingLeft: 0, marginTop: -3, fontWeight: 400, fontSize: Math.max(12 - reportLocations.length, 6), fontFamily: 'Nunito Sans' }}>Wkend Avg</Typography>
                            <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 0, marginTop: 1, fontWeight: 600, fontSize: Math.max(20 - reportLocations.length, 10), fontFamily: 'Nunito Sans', color: (byDateData[idx].perChange.wkendAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(byDateData[idx].perChange.wkendAvg >= 0) ? '+' : ''}{(byDateData[idx].perChange.wkendAvg).toFixed(0)}<small>%</small></Typography>
                            <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 0, marginTop: -5, fontWeight: 400, fontSize: Math.max(11 - reportLocations.length, 5), fontFamily: 'Nunito Sans' }}>vs. prev {dateLabels.abr}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ]
                ))}
              </Grid>

              {/* ROW 2 */}
              <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: 12, height: pageHeight / 5, width: '100%' }}>

                <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '48%', height: '100%' }}>
                  <Typography style={{ width: '100%', paddingLeft: 0, fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans' }}>Highlights</Typography>

                  {/* Used by Puppeteer with customized highlights section, reportHighlights will always be empty when using dashboard */}
                  {reportHighlights !== '' &&
                    <Typography className={(reportClient !== 'lee' && !isFlagship) ? classes.highlights : classes.highlightsExtra} component='div' dangerouslySetInnerHTML={{ __html: reportHighlights }} />
                  }

                  {reportClient !== 'lee' && !isFlagship && reportHighlights === '' &&
                    <Typography className={classes.highlights} component='div' contentEditable suppressContentEditableWarning onBlur={this.props.handleHighlightsChange}>
                      {(reportByWeek < 3 ? ('During the ' + dateLabels.lower + ' of ') : '') + dateLabels.highlights + ', ' + locationLabels[0] + ' pedestrian traffic totaled ' + byDateData[0].totalTraffic.toLocaleString() + ' people. ' + locationLabels[1] + ' pedestrian traffic was '}
                      <strong><em>{(((byDateData[1].totalTraffic - byDateData[0].totalTraffic) / byDateData[0].totalTraffic) * 100).toFixed(0) + '%'}</em></strong>
                      {(byDateData[1].totalTraffic > byDateData[0].totalTraffic ? ' higher' : ' lower') + ' than ' + locationLabels[0] + ', totaling ' + byDateData[1].totalTraffic.toLocaleString() + ' people.'}
                    </Typography>
                  }

                  {(reportClient === 'lee' || isFlagship) && reportHighlights === '' &&
                    <Typography className={classes.highlightsExtra} component='div' contentEditable suppressContentEditableWarning onBlur={this.props.handleHighlightsChange}>
                      {reportLocations.map((s, idx) => (
                        [
                          (idx < 1)
                            ? (reportByWeek < 3 ? ('During the ' + dateLabels.lower + ' of ') : '') + dateLabels.highlights + ', ' + locationLabels[0] + ' pedestrian traffic totaled ' + byDateData[0].totalTraffic.toLocaleString() + ' people. '
                            : <Fragment key={idx}>{locationLabels[idx]} pedestrian traffic was <strong><em>{(Math.abs((byDateData[idx].totalTraffic - byDateData[0].totalTraffic) / byDateData[0].totalTraffic) * 100).toFixed(0)}%</em></strong>{(byDateData[idx].totalTraffic > byDateData[0].totalTraffic ? ' higher' : ' lower')} than {locationLabels[0]}, totaling {byDateData[idx].totalTraffic.toLocaleString()} people. </Fragment>
                        ]
                      ))}
                      {isFlagship &&
                        <><br /><br />The weather in New York City from 10/28/22 to 10/30/22 was sunny with an average low of 44°F and an average high of 62°F.</>
                      }
                    </Typography>
                  }
                </Grid>

                <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '49%', height: '100%' }}>
                  <Grid container justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%' }}>
                    <ByTimeAllReport
                      series={byTimeChartData} />
                  </Grid>
                </Grid>

              </Grid>

              {/* BY DATE ROW */}
              <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: 0, height: pageHeight / 2.9, width: '100%' }}>
                <ByDateReport
                  series={byDateChartData}
                  // interval={reportByWeek}
                  interval={reportDateInterval} />
              </Grid>

              {/* FOOTER */}
              <Grid container item direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight * 0.05 }}>
                <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: 1 }}></Grid>
                <Grid container direction='column' justifyContent='flex-end' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight / 22 }}>

                  <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '50%' }}>
                    <Typography style={{ marginTop: 0, marginBottom: -4, fontWeight: 700, fontSize: 18, fontFamily: 'Rajdhani' }}>Traffic Report</Typography>
                  </Grid>

                  <Grid item container direction='row' justifyContent='flex-end' alignItems='flex-start' style={{ height: '100%', width: '50%' }}>
                    <img style={{ height: '100%' }} alt='Exteros Logo' src={require('../../layout/exteros_blue.png')} />
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* 2nd page if detailed report requested */}
          {detailed && reportLocations.map((s, idx) => (
            [
              <Fragment key={idx}>
                {!isPuppeteer &&
                  <Grid style={{ height: 'calc(100px + 0vh)' }}></Grid> // spacing between pages
                }

                <Grid container direction='column' justifyContent='center' alignItems='center' style={{ width: pageWidth, height: pageHeight, backgroundColor: 'white' }}>
                  <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight * 0.9545, marginTop: pageWidth / 32, marginBottom: pageWidth / 32 }}>

                    {/* AVERAGES HEADER */}
                    <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight * 0.296 }}>
                      <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '53%', height: '100%', marginRight: '0%' }}>

                        <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%', height: reportClient === 'lee' || isFlagship ? pageHeight / 16 : pageHeight / 18, backgroundColor: alpha(chartColors[idx], 0.6) }}>
                          <Typography
                            style={locationLabels[idx].length < 40
                              ? { paddingLeft: 10, marginTop: reportClient === 'lee' || isFlagship ? 9 : 5, fontWeight: 600, fontSize: 23, fontFamily: 'Rajdhani' }
                              : { paddingLeft: 10, marginTop: reportClient === 'lee' || isFlagship ? 9 : 5, fontWeight: 600, fontSize: 20, fontFamily: 'Rajdhani' }
                            }>
                            {locationLabels[idx]}
                          </Typography>
                          <Typography style={{ paddingLeft: 10, marginTop: -5, fontWeight: 400, fontSize: 13, fontFamily: 'Nunito Sans' }}>{dateLabels.mainHeader}</Typography>
                        </Grid>

                        <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: 0, height: reportClient === 'lee' || isFlagship ? pageHeight / 12 : pageHeight / 10, width: '100%' }}>
                          <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', backgroundColor: '#efefefff' }}>
                            <Typography style={{ paddingLeft: 20, width: '100%', marginTop: reportClient === 'lee' || isFlagship ? 20 : 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{byDateData[idx].avgsFormatted.dailyAvg}&thinsp;<small>{(byDateData[idx].avgs.dailyAvg >= 1000) ? 'K' : ''}</small></Typography>
                            <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -8, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Daily Avg</Typography>
                            <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 20, width: '100%', marginTop: 2, fontWeight: 600, fontSize: 20, fontFamily: 'Nunito Sans', color: (byDateData[idx].perChange.dailyAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(byDateData[idx].perChange.dailyAvg >= 0) ? '+' : ''}{(byDateData[idx].perChange.dailyAvg).toFixed(1)}<small>%</small></Typography>
                            <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 20, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>vs. prev {dateLabels.lower}</Typography>
                          </Grid>
                          <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', backgroundColor: '#efefefff' }}>
                            <Typography style={{ paddingLeft: 20, width: '100%', marginTop: reportClient === 'lee' || isFlagship ? 20 : 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{byDateData[idx].avgsFormatted.wkdayAvg}&thinsp;<small>{(byDateData[idx].avgs.wkdayAvg >= 1000) ? 'K' : ''}</small></Typography>
                            <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -8, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Wkday Avg</Typography>
                            <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 20, width: '100%', marginTop: 2, fontWeight: 600, fontSize: 20, fontFamily: 'Nunito Sans', color: (byDateData[idx].perChange.wkdayAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(byDateData[idx].perChange.wkdayAvg >= 0) ? '+' : ''}{(byDateData[idx].perChange.wkdayAvg).toFixed(1)}<small>%</small></Typography>
                            <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 20, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>vs. prev {dateLabels.lower}</Typography>
                          </Grid>
                          <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '32%', height: '100%', backgroundColor: '#efefefff' }}>
                            <Typography style={{ paddingLeft: 20, width: '100%', marginTop: reportClient === 'lee' || isFlagship ? 20 : 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{byDateData[idx].avgsFormatted.wkendAvg}&thinsp;<small>{(byDateData[idx].avgs.wkendAvg >= 1000) ? 'K' : ''}</small></Typography>
                            <Typography style={{ paddingLeft: 20, width: '100%', marginTop: -8, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Wkend Avg</Typography>
                            <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 20, width: '100%', marginTop: 2, fontWeight: 600, fontSize: 20, fontFamily: 'Nunito Sans', color: (byDateData[idx].perChange.wkendAvg >= 0) ? '#56993B' : '#BF3E37' }}>{(byDateData[idx].perChange.wkendAvg >= 0) ? '+' : ''}{(byDateData[idx].perChange.wkendAvg).toFixed(1)}<small>%</small></Typography>
                            <Typography style={{ display: reportClient === 'lee' || isFlagship || !change ? 'none' : '', paddingLeft: 20, width: '100%', marginTop: -5, fontWeight: 400, fontSize: 10, fontFamily: 'Nunito Sans' }}>vs. prev {dateLabels.lower}</Typography>
                          </Grid>
                        </Grid>

                        <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ marginTop: 0, height: reportClient === 'lee' || isFlagship ? pageHeight / 14 : pageHeight / 16, width: '100%' }}>
                          <Grid container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '49%', height: '100%', backgroundColor: '#efefefff' }}>
                            <Typography style={{ paddingLeft: 20, marginTop: reportClient === 'lee' || isFlagship || !change ? 13 : 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{byTimeData[idx].busiestHr.hr}&thinsp;<small>{byTimeData[idx].busiestHr.suffix}</small></Typography>
                            <Typography style={{ paddingLeft: 20, marginTop: -6, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Busiest Hour</Typography>
                          </Grid>
                          <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ width: '49%', height: '100%', backgroundColor: '#efefefff' }}>
                            <Typography style={{ paddingLeft: 20, marginTop: reportClient === 'lee' || isFlagship || !change ? 13 : 8, fontWeight: 700, fontSize: 24, fontFamily: 'Nunito Sans' }}>{byDateData[idx].busiestDay}</Typography>
                            <Typography style={{ paddingLeft: 20, marginTop: -6, fontWeight: 400, fontSize: 12, fontFamily: 'Nunito Sans' }}>Busiest Day</Typography>
                          </Grid>
                        </Grid>

                        <Grid item container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ display: showDemoArray[idx] ? '' : 'none', marginTop: 5, marginBottom: 5, width: '100%', height: pageHeight / 20 }}>
                          <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ marginTop: 0, width: '49%', height: pageHeight / 20 }}>
                            <MonthlyAveragePie
                              age={false} gender={true}
                              series={[{ data: [{ genderPie: byDateData[idx].genderPie }] }]} />
                          </Grid>
                          <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ marginTop: 0, width: '49%', height: pageHeight / 20 }}>
                            <MonthlyAveragePie
                              age={true} gender={false}
                              series={[{ data: [{ agePie: byDateData[idx].agePie }] }]} />
                          </Grid>
                        </Grid>

                      </Grid>

                      <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '45%', height: pageHeight / 3.17, marginLeft: '1%' }}>
                        <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%' }}>

                          {!mapRegions[idx].color &&
                            <img style={{ width: '99.2%' }} alt='Location Map' src={getImage(client + '/' + locationLabels[idx].replace('ä', 'a').replaceAll(/[\s\+\']+/g, '_') + '_Square.jpg', true)} />
                          }

                          {mapRegions[idx].color &&
                            <div
                              style={{
                                width: '100%',
                                // height: '100%',
                                height: '332px',
                                overflow: 'hidden',
                                position: 'relative',
                                clipPath: 'inset(0 0 0 0)',
                              }}
                            >
                              <GoogleMap
                                mapContainerStyle={{ width: '100%', height: '110%' }}
                                options={{
                                  mapId: '10324736f3dd8795',
                                  mapTypeControl: false,
                                  streetViewControl: false,
                                  fullscreenControl: false,
                                  zoomControl: false, // Disable zoom control
                                  rotateControl: false, // Disable rotate control
                                  scaleControl: false, // Disable scale control
                                  panControl: false, // Disable pan control
                                  disableDefaultUI: true, // Disable all default UI controls
                                }}
                                zoom={17.0}
                                center={mapRegions[idx].position}
                              >
                                <Polygon
                                  paths={mapRegions[idx].polygonCoordinates}
                                  options={{
                                    fillColor: mapRegions[idx].color,
                                    fillOpacity: 0.3,
                                    strokeColor: darkenColor(mapRegions[idx].color, 30),
                                    strokeWeight: 3,
                                    strokeOpacity: 0.9,
                                    zIndex: 1
                                  }}
                                />
                              </GoogleMap>
                            </div>
                          }

                        </Grid>
                      </Grid>

                    </Grid>

                    {/* GRAPHS */}
                    <Grid container item direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight * 0.56 }}>

                      <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '53%', height: pageHeight * 0.56 }}>
                        <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: '100%' }}>
                          <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans', width: '100%' }}>
                            Avg. Daily Traffic: Time by Day of Week
                          </Grid>
                          <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start'>
                            <ByDayHourlyTable
                              series={[byTimeData[idx].byDayHourly]}
                              remDays={isFlagship ? [0, 1, 2, 3] : []} />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '45%', height: pageHeight * 0.56 }}>
                        <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%', height: showDemoArray[idx] ? pageHeight / 6.7 : pageHeight / 5 }}>
                          <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans', width: '100%' }}>
                            Traffic By Day of Week
                          </Grid>
                          <ByDayOfWeekReport
                            series={[byDateData[idx].dayOfWeekChart]}
                            demo={showDemoArray[idx]}
                          />
                        </Grid>

                        {/* Demographics OFF - Traffic by Time */}
                        {!showDemoArray[idx] &&
                          <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ display: showDemoArray[idx] ? 'none' : '', width: '100%', height: pageHeight / 2.9, marginTop: 10 }}>
                            <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%', height: pageHeight / 7.8 }}>
                              <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans', width: '100%' }}>
                                Traffic By Time of Day
                              </Grid>
                              <ByTimeReport
                                age={false} gender={false}
                                series={[byTimeData[idx].byTimeSplit]}
                                color={chartColors[idx]} />
                            </Grid>
                          </Grid>
                        }

                        {/* Demographics ON - Traffic by Time */}
                        {showDemoArray[idx] &&
                          <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ display: showDemoArray[idx] ? '' : 'none', width: '100%', height: pageHeight / 2.53 }}>
                            <Grid item container direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ fontWeight: 700, fontSize: 17, fontFamily: 'Nunito Sans', width: '100%' }}>
                              Demographics By Time of Day
                            </Grid>
                            <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: pageHeight / 2.75, marginTop: 0, marginBottom: 0 }}>
                              <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: pageHeight / 5.65, marginTop: 0, marginBottom: 0 }}>
                                <Grid item container direction='row' justifyContent='center' alignItems='flex-start' style={{ backgroundColor: '#efefefff', paddingTop: 3, paddingBottom: 2, fontWeight: 700, marginTop: 0, fontSize: 13, fontFamily: 'Nunito Sans', width: '100%' }}>
                                  Gender
                                </Grid>
                                <Grid item container direction='row' justifyContent='center' alignItems='center' style={{ width: '100%', marginTop: 0, marginBottom: 0 }}>
                                  <Grid style={{ marginRight: 4, width: 10, height: 10, backgroundColor: '#6089c4' }}></Grid>
                                  <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }}> Male</Typography>
                                  <Grid style={{ marginLeft: 40, marginRight: 4, width: 10, height: 10, backgroundColor: '#d77da5' }}></Grid>
                                  <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }}> Female</Typography>
                                </Grid>
                                <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%', height: pageHeight / 7.8 }}>
                                  <ByTimeReport
                                    age={false} gender={true}
                                    series={[byTimeData[idx].byTimeSplit]} />
                                </Grid>
                              </Grid>
                              <Grid item container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: '100%', height: pageHeight / 5.65, marginTop: 0, marginBottom: 0 }}>
                                <Grid item container direction='row' justifyContent='center' alignItems='flex-start' style={{ backgroundColor: '#efefefff', paddingTop: 3, paddingBottom: 2, fontWeight: 700, marginTop: 0, fontSize: 13, fontFamily: 'Nunito Sans', width: '100%' }}>
                                  Age
                                </Grid>
                                <Grid item container direction='row' justifyContent='center' alignItems='center' style={{ width: '100%', marginTop: 0, marginBottom: 0 }}>
                                  <Grid style={{ marginRight: 4, width: 10, height: 10, backgroundColor: '#7ea84e' }}> </Grid>
                                  <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }}> Children</Typography>
                                  <Grid style={{ marginLeft: 40, marginRight: 4, width: 10, height: 10, backgroundColor: '#643d23' }}> </Grid>
                                  <Typography style={{ fontWeight: 600, fontSize: 12, fontFamily: 'Nunito Sans' }}> Seniors</Typography>
                                </Grid>
                                <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '100%', height: pageHeight / 7.8 }}>
                                  <ByTimeReport
                                    age={true} gender={false}
                                    series={[byTimeData[idx].byTimeSplit]} />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        }

                      </Grid>
                    </Grid>

                    {/* FOOTER */}
                    <Grid container item direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight * 0.05 }}>
                      <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: 1 }}></Grid>
                      <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' style={{ width: pageWidth * 0.938, height: pageHeight / 22 }}>

                        <Grid item container direction='row' justifyContent='flex-start' alignItems='flex-start' style={{ width: '50%' }}>
                          <Typography style={{ marginTop: -7, fontWeight: 700, fontSize: 30, fontFamily: 'Rajdhani', width: '100%' }}>{this.props.auth.user.email.includes('absre') ? '200 Park Ave South' : isFlagship ? '5th Ave Flagship Study' : reportTitle}</Typography>
                          <Typography style={{ marginTop: -9, fontWeight: 700, fontSize: 18, fontFamily: 'Rajdhani' }}>Traffic Report</Typography>
                        </Grid>

                        <Grid item container direction='row' justifyContent='flex-end' alignItems='flex-start' style={{ height: '100%', width: '50%' }}>
                          <img style={{ height: '100%' }} alt='Client Logo' src={require('../../layout/exteros_blue.png')} />
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Fragment>
            ]
          ))}
        </LoadScriptNext>
      </>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(withStyles(styles)(MultiLocationReport));
