import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts';
import CountUp from 'react-countup';
import { Card, CircularProgress, Grid, ImageList, ImageListItem, Typography, Tooltip } from '@material-ui/core';
import { alpha, withStyles } from '@material-ui/core/styles';
import Zoom from '@mui/material/Zoom';

import { getImage } from '../../utils/reportHelpers';
import { reduceArrayAverages, getColor,darkenColor } from '../../utils/helpers';
import { colorsMen, colorsWomen, colorsChildren, colorsAdults, colorsSeniors, colorsBikes, colorsBuses, colorsCars, colorsTrucks } from '../../utils/colors';
import { GoogleMap, LoadScript, Marker, Polygon } from '@react-google-maps/api';

const GENDER_COLORS = [colorsWomen[0], colorsMen[0]];
const AGE_COLORS = [colorsChildren[0], colorsAdults[0], colorsSeniors[0]];
const VEHICLE_COLORS = [colorsBikes[0], colorsBuses[0], colorsCars[0], colorsTrucks[0]];

const week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const weekdayLookup = ['-'].concat(week, week, week, week, week);
const startFontSize = 14;
const startLabelFontSize = 8;
const RADIAN = Math.PI / 180;
const vTypesRegex = / \[.*\]/;

const styles = theme => ({
  menuItem: {
    // color: theme.palette.primary.main
  },
  gridList: {
    width: '100%',
    height: '100%',
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    // transform: 'translateZ(0)',
  },
  table: {
    minWidth: 600,
  },
  tablecell: {
    // fontSize: '1vw'
  },
  tableArea: {
    paddingLeft: 0,
    paddingRight: 0,
  }
});

class AveragesHeader extends PureComponent {
  // constructor(props) {
  //   super(props);
  // }


  renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name, fill, maxRadius, index }) => {
    var height = this.props.windowHeight;
    var width = this.props.windowWidth;
    var radius = (innerRadius + (outerRadius - innerRadius)) * (height / 220) * (450 / height)+7;
    var x = cx + radius * Math.cos(-midAngle * RADIAN);
    // const y = cy + radius * Math.sin(-midAngle * RADIAN) * 3;
    var centerXtitle = cx + radius * Math.cos(-90 * RADIAN);
    let centerAngle = 45;
    if (midAngle > 90) {
      centerAngle = 135;
    }
    var centerX = cx + radius * Math.cos(-centerAngle * RADIAN);
    let title = '';
    if (maxRadius === 200) {
      title = 'Daily Avg';
    } else if (maxRadius === 201) {
      title = 'Weekend Avg';
    } else if (maxRadius === 202) {
      title = 'Weekday Avg';
    }
    return (
      <g>
        <text fontSize={5 + (width / 470) + (height /100)} x={centerX} y={'46%'} fontWeight={500} fill={fill} textAnchor={x > cx ? 'end' : 'start'} dominantBaseline='central'>
          {`${(percent * 100).toFixed(0)}%`}
        </text>
        <text fontSize={2 + (width / 470) + (height /100)}  fontWeight={300} x={centerX} y={'22%'} fill='black' textAnchor={x > cx ? 'end' : 'start'} dominantBaseline='central'>
          {name}
        </text>
        <text display={index === 0 ? '' : 'none'} fontSize={2 + (width / 470) + (height /100)} fontWeight={400} x={centerXtitle} y={(width / 160 + height / 20)+10} fill='black' textAnchor={'middle'} dominantBaseline='central'>
          {title}
        </text>
      </g>
    );
  };
    
  renderCustomizedLabelAge = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name, fill, index, maxRadius }) => {
    var height = this.props.windowHeight;
    var width = this.props.windowWidth;
    var adultRadius = (innerRadius + (outerRadius - innerRadius)) * (height / 220) * (450 / height);
    var radius = (innerRadius + (outerRadius - innerRadius)) * (height / 190) * (450 / height);
    var x = cx + radius * Math.cos(-midAngle * RADIAN);
    // const y = cy + radius * Math.sin(-midAngle * RADIAN) * 3;
    let centerAngle = 45;
    if (midAngle > 90) {
      centerAngle = 135;
    }
    var centerX = cx + radius * Math.cos(-centerAngle * RADIAN);
    var centerXtitle = cx + adultRadius * Math.cos(-90 * RADIAN);
    let title = '';
    if (maxRadius === 200) {
      title = 'Daily Avg';
    } else if (maxRadius === 201) {
      title = 'Weekend Avg';
    } else if (maxRadius === 202) {
      title = 'Weekday Avg';
    }
    return (
      <g>
        <text display={name === 'Adults' ? '' : 'none'} fontSize={5 + (width / 450) + (height /100)} x={centerXtitle} y={(width / 380 + height / 29)+9} fontWeight={500} fill={fill} textAnchor={'middle'} dominantBaseline='central'>
          {`${(percent * 100).toFixed(0)}%`}
        </text>
        <text display={name === 'Adults' ? '' : 'none'} fontSize={1 + (width / 400) + (height /100)} fontWeight={300} x={centerXtitle} y={'33%'} fill='black' textAnchor={'middle'} dominantBaseline='central'>
          {name}
        </text>
        <text display={name !== 'Adults' ? '' : 'none'} fontSize={5 + (width / 450) + (height /100)} x={centerX+0} y={(width / 220 + height / 19)+9} fontWeight={500} fill={fill} textAnchor={x > cx ? 'end' : 'start'} dominantBaseline='central'>
          {`${(percent * 100).toFixed(0)}%`}
        </text>
        <text display={name === 'Seniors'? '' : 'none'} fontSize={1 + (width / 400) + (height /100)} fontWeight={300} x={centerX-2} y={'49%'} fill='black' textAnchor={x > cx ? 'end' : 'start'} dominantBaseline='central'>
          {name}
        </text>
        <text display={name === 'Children'? '' : 'none'} fontSize={1 + (width / 400) + (height /100)} fontWeight={300} x={centerX-0} y={'49%'} fill='black' textAnchor={x > cx ? 'end' : 'start'} dominantBaseline='central'>
          {name}
        </text>
        <text display={index === 0 ? '' : 'none'} fontSize={1 + (width / 400) + (height /100)} fontWeight={400} x={centerXtitle} y={(width / 180 + height / 19)+10} fill='black' textAnchor={'middle'} dominantBaseline='central'>
          {title}
        </text>
      </g>
    );
  };

  renderCustomizedLabelVehicle = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name, fill, index, maxRadius }) => {
    const height = this.props.windowHeight;
    const width = this.props.windowWidth;
    const centerRadius = (innerRadius + (outerRadius - innerRadius)) * (height / 220) * (450 / height);
    const radius = (innerRadius + (outerRadius - innerRadius)) * (height / 190) * (450 / height);

    let centerAngle = 45;
    if (midAngle > 90) {
      centerAngle = 135;
    }
    const centerX = cx + radius * Math.cos(-centerAngle * RADIAN);
    const centerXtitle = cx + centerRadius * Math.cos(-90 * RADIAN);

    let title = '';
    if (maxRadius === 200) {
      title = 'Daily Avg';
    } else if (maxRadius === 201) {
      title = 'Weekend Avg';
    } else if (maxRadius === 202) {
      title = 'Weekday Avg';
    }

    return (
      <g>
        <text display={name === 'Bikes' ? '' : 'none'} fontSize={1 + (width / 400) + (height / 100)} fontWeight={300} x={centerX - 2} y={'49%'} fill='black' textAnchor={'start'} dominantBaseline='central'>
          {name}
        </text>
        <text display={name === 'Bikes' ? '' : 'none'} fontSize={5 + (width / 450) + (height / 100)} x={centerX + 0} y={(width / 220 + height / 19) + 9} fontWeight={500} fill={fill} textAnchor={'start'} dominantBaseline='central'>
          {`${(percent * 100).toFixed(0)}%`}
        </text>

        <text display={name === 'Buses' ? '' : 'none'} fontSize={5 + (width / 450) + (height / 100)} x={centerXtitle - 25} y={(width / 380 + height / 29) + 9} fontWeight={500} fill={fill} textAnchor={'middle'} dominantBaseline='central'>
          {`${(percent * 100).toFixed(0)}%`}
        </text>
        <text display={name === 'Buses' ? '' : 'none'} fontSize={1 + (width / 400) + (height / 100)} fontWeight={300} x={centerXtitle - 25} y={'33%'} fill='black' textAnchor={'middle'} dominantBaseline='central'>
          {name}
        </text>

        <text display={name === 'Cars' ? '' : 'none'} fontSize={5 + (width / 450) + (height / 100)} x={centerXtitle + 25} y={(width / 380 + height / 29) + 9} fontWeight={500} fill={fill} textAnchor={'middle'} dominantBaseline='central'>
          {`${(percent * 100).toFixed(0)}%`}
        </text>
        <text display={name === 'Cars' ? '' : 'none'} fontSize={1 + (width / 400) + (height / 100)} fontWeight={300} x={centerXtitle + 25} y={'33%'} fill='black' textAnchor={'middle'} dominantBaseline='central'>
          {name}
        </text>

        <text display={name === 'Trucks' ? '' : 'none'} fontSize={1 + (width / 400) + (height / 100)} fontWeight={300} x={centerX - 0} y={'49%'} fill='black' textAnchor={'end'} dominantBaseline='central'>
          {name}
        </text>
        <text display={name === 'Trucks' ? '' : 'none'} fontSize={5 + (width / 450) + (height / 100)} x={centerX + 0} y={(width / 220 + height / 19) + 9} fontWeight={500} fill={fill} textAnchor={'end'} dominantBaseline='central'>
          {`${(percent * 100).toFixed(0)}%`}
        </text>

        <text display={index === 0 ? '' : 'none'} fontSize={1 + (width / 400) + (height / 100)} fontWeight={400} x={centerXtitle} y={(width / 180 + height / 19) + 10} fill='black' textAnchor={'middle'} dominantBaseline='central'>
          {title}
        </text>
      </g>
    );
  };

  render() {
    const { classes, mode, multDates, numPlots, series, vehicles, windowHeight, windowWidth } = this.props;

    let dataPoints = 0;
    let secondDateCardIndex = 0;
    let subTitleColor = '#616161'
    try { secondDateCardIndex = series.length - 1; }
    catch (e) {}

    let chartArray = reduceArrayAverages(series, numPlots, multDates, secondDateCardIndex);
    try {
      for (let ser in chartArray) {
        if (chartArray[ser].data.length > dataPoints) {
          dataPoints = chartArray[ser].data.length;
        }
      }
    } catch (err) {}

    // const col2 = 1100
    // const col3 = 1550   1100 800         800  1000         600   750    460  610    390   530
    const col2 = 1200;
    const col3 = 1550;
    const col4 = 800;

    const col2age = 1370;
    const col3age = 2100;
    const col4age = 750;
    // const ageMult = 1.4
    // + 5000/windowHeight
    const heightDiv = 1;
    const widthAdd = 1300;
    const ratioExp = 1;

    return (
      <Fragment>
        {dataPoints === 0 &&
          <Grid container style={{ minHeight: 80, height: windowHeight * 0.12, width: '100%' }}>
            <Card raised={false} style={{ margin: 2, position: 'relative', width: '100%', height: '100%' }}>
              <Grid container justifyContent='space-around' alignItems='center'>
                <CircularProgress size={'calc(1vw + 1vh)'} style={{ position: 'absolute', top: windowHeight * 0.045, left: windowWidth / 2.05 }} />
              </Grid>
            </Card>
          </Grid>
        }
        {dataPoints > 0 && (windowWidth / numPlots - 5000 / windowHeight) > 55 &&
          <Fragment>    
            {this.props.openTab === 0 &&
              <ImageList gap={0} className={classes.gridList} cols={multDates ? 2 : numPlots} style={{ backgroundColor: '', marginBottom: '0', minHeight: 80, height: '100%', display: (this.props.count && numPlots > 0 && dataPoints > 0) ? '' : 'none' }}>
                {chartArray.map((s, idx) => (
                  [
                    <ImageListItem key={'cd' + idx} style={{minHeight: 77, height: '100%', display: (typeof (s.locationLabels[0]) !== 'undefined') ? '' : 'none' }}>
                      <Card raised={false} style={{minHeight: 77, marginRight:4, marginBottom: 3, display: (typeof (s.locationLabels[0]) !== 'undefined') ? '' : '' }}>
                        {/* <Fragment style={{height:'100%'}}> */}
                        <Grid xs={12} item container direction='column' justifyContent='flex-start' alignItems='stretch' style={{ backgroundColor: '', height: (windowHeight * 0.13) - 4, minHeight: 77}}>
                          <Grid item container direction='row' xs={numPlots > 2 || windowWidth / numPlots < 350 || Object.values(this.props.locationsObject['labelList']).indexOf(s.locationLabels[0]?.replace(vTypesRegex, '')) == -1 ? 12 : 10} justifyContent="flex-start" alignItems="flex-start" style={{ height: '100%', paddingLeft: '0', backgroundColor: '', position: 'relative' }}>
                            <Grid  container item direction='column' justifyContent='flex-start' alignItems='flex-start' style={{ backgroundColor: '', position: 'relative', height: windowHeight * 0.03,minHeight: 27 }}>
                              <svg style={{ width: numPlots>1?'100%':'100%', height: windowHeight * 0.03, minHeight: 27, position: 'relative', top: 0 }}>
                                <defs>
                                  <pattern id="pattern-stripe"
                                    width="9" height="8"
                                    patternUnits="userSpaceOnUse"
                                    patternTransform="rotate(45)">
                                    <rect width="7" height="8" transform="translate(0,0)" fill="white"></rect>
                                  </pattern>
                                  <mask id="mask-stripe">
                                    <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe)" />
                                  </mask>
                                </defs>
                                <rect fill={alpha(getColor(s.locationLabels, idx, this.props.locationsObject, chartArray), 0.4)}
                                  mask={parseInt(s.name.substr(s.name.length - 3)[0]) > 1 ? "url(#mask-stripe)" : ""}
                                  x="0"
                                  y="0"
                                  width="100%"
                                  height="100%" />
                                <rect fill={alpha(getColor(s.locationLabels, idx, this.props.locationsObject, chartArray), 0.3)}
                                  x="0"
                                  y="0"
                                  width="100%"
                                  height="100%" />
                              </svg>
                              <Typography noWrap wrap='nowrap' style={{ marginTop: Math.min(-windowHeight * 0.026-0, -21), zIndex: 1, width: '100%', fontWeight: 600, fontSize: 'calc(' + (Math.max(4, Math.round((startFontSize) / ((numPlots+2))) + 4)).toString() + 'px + 0.2vw)', paddingTop: 0, paddingBottom: 0, paddingLeft: 10, marginBottom: 0 }}>
                                {s.locationLabels.join(', ')} {(multDates) ? ' (' + s.dateLabel + ')' : ''}
                              </Typography> 

                         </Grid>
                          
                          <Grid xs={12} container item direction='row' justifyContent="flex-start" alignItems="stretch" style={{ marginTop: 0, marginBottom: 0, height: (windowHeight * 0.12) + Math.min(-windowHeight * 0.03, -27) - 4, minHeight: 50, backgroundColor: '', paddingBottom: 0 }}>
                            <Grid xs container item direction='row' justifyContent="flex-start" alignItems="stretch" style={{height: '100%', marginBottom:0, backgroundColor: '', minWidth: 80}}>
                              <Grid xs={6} container item direction='row' justifyContent="flex-start" alignItems="stretch" style={{marginTop: 0, paddingLeft:'calc(' + (Math.max(4, Math.round((startFontSize) / ((numPlots+2))) + 4)).toString() + 'px + 0.2vw)', fontWeight: 500, fontSize: 'calc(' + (Math.max(4, Math.round((startFontSize) / ((numPlots+2))) + 10)).toString() + 'px + 0.6vw)', backgroundColor: '', }}>
                                <Grid container  item direction='row' justifyContent="flex-start" alignItems="flex-end"  style={{marginBottom:-3,height:'60%',backgroundColor:''}}>
                                <CountUp
                                    start={0}
                                    end={s.countAvg.current.avgDaily >= 1000 ? s.countAvg.current.avgDaily / 1000 : s.countAvg.current.avgDaily}
                                    duration={2.2}
                                    delay={0}
                                    separator=','
                                    decimals={(s.countAvg.current.avgDaily >= 1000 || mode === 'Conversion') ? 1 : 0}
                                    decimal='.'
                                    prefix=''
                                    suffix={s.countAvg.current.avgDaily >= 1000 ? '&#8239;<small>K</small>' : (mode === 'Conversion' ? '&#8239;<small>%</small>' : mode === 'Dwell' ? '&#8239;<small>sec</small>':'&#8239;')}
                                  >
                                    {({ countUpRef }) => (
                                      <div>
                                        <span ref={countUpRef} />
                                      </div>
                                    )}
                                  </CountUp>
                                  </Grid>
                                  <Typography noWrap style={{ fontWeight: 400, height:'40%',fontSize: 'calc(' + (Math.max(4, Math.round((startFontSize) / ((numPlots+2))) + 3)).toString() + 'px + 0.2vw)' , paddingBottom: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: '' }}>
                                    {this.props.occupancy ? 'Daily Peak Avg' : 'Daily Avg'}
                                  </Typography>
                              </Grid>
                              <Tooltip title={<p style={{ fontSize: 14 }}> {s.prevDateLabel} </p>} placement='bottom-start' TransitionComponent={Zoom}>
                                <Grid xs={6} container item direction='row' justifyContent="flex-start" alignItems="stretch" style={{cursor: 'help', display: mode === 'Conversion' || mode === 'Dwell' || windowWidth / numPlots < 160 ? 'none' : '', color: s.countAvg.delta.avgDaily >= 0 ? '#56993B' : '#BF3E37', fontWeight: 500, fontSize: 'calc(' + (Math.max(3, Math.round((startFontSize) / ((numPlots+2))) + 8)).toString() + 'px + 0.5vw)', backgroundColor: '', marginTop: 0, paddingLeft:'calc(' + (Math.max(4, Math.round((startFontSize) / ((numPlots+2))) + 2)).toString() + 'px + 0.2vw)', fontWeight: 500, backgroundColor: '' }}>                                    
                                    <Grid container  item direction='row' justifyContent="flex-start" alignItems="flex-end"  style={{height:'60%',backgroundColor:'',marginBottom:-4}}>
                                      <CountUp
                                        start={0}
                                        end={s.countAvg.delta.avgDaily}
                                        duration={2.2}
                                        delay={0}
                                        separator=','
                                        decimals={0}
                                        decimal='.'
                                        prefix={s.countAvg.delta.avgDaily >= 0 ? '+' : ''}
                                        suffix={'&#8239;<small>%</small>'}                                   
                                      >
                                        {({ countUpRef }) => (
                                          <div>
                                            <span ref={countUpRef} />
                                          </div>
                                        )}
                                      </CountUp>
                                      </Grid>
                                        <Typography noWrap style={{ height:'40%',color: subTitleColor, fontWeight: 400, display: '', fontSize: 'calc(' + (Math.max(4, Math.round((startFontSize) / ((numPlots+2))) + 2)).toString() + 'px + 0.2vw)', paddingTop: 0, paddingLeft: 0, paddingRight: 0, backgroundColor:'' }}>
                                          vs.&#8239;prev&#8239;&#9432;
                                        </Typography>
                                </Grid>
                              </Tooltip>
                            </Grid>
                              <Grid xs item container direction='row' justifyContent="flex-start" alignItems="stretch" style={{ height:'100%',paddingLeft: '0', backgroundColor: '', minWidth: 80 }}>
                              <Grid xs={6} container item direction='row' justifyContent="flex-start" alignItems="stretch" style={{marginTop: 0, paddingLeft:'calc(' + (Math.max(4, Math.round((startFontSize) / ((numPlots+2))) + 4)).toString() + 'px + 0.2vw)', fontWeight: 500, fontSize: 'calc(' + (Math.max(4, Math.round((startFontSize) / ((numPlots+2))) + 10)).toString() + 'px + 0.6vw)', backgroundColor: '', }}>
                                <Grid container  item direction='row' justifyContent="flex-start" alignItems="flex-end"  style={{marginBottom:-3,height:'60%',backgroundColor:''}}>
                                <CountUp
                                    start={0}
                                    end={s.countAvg.current.avgWeekend >= 1000 ? s.countAvg.current.avgWeekend / 1000 : s.countAvg.current.avgWeekend}
                                    duration={2.2}
                                    delay={0}
                                    separator=','
                                    decimals={(s.countAvg.current.avgWeekend>= 1000 || mode === 'Conversion') ? 1 : 0}
                                    decimal='.'
                                    prefix=''
                                    suffix={s.countAvg.current.avgDaily >= 1000 ? '&#8239;<small>K</small>' : (mode === 'Conversion' ? '&#8239;<small>%</small>' : mode === 'Dwell' ? '&#8239;<small>sec</small>':'&#8239;')}
                                  >
                                    {({ countUpRef }) => (
                                      <div>
                                        <span ref={countUpRef} />
                                      </div>
                                    )}
                                  </CountUp>
                                  </Grid>
                                  <Typography noWrap style={{ fontWeight: 400, height:'40%',fontSize: 'calc(' + (Math.max(4, Math.round((startFontSize) / ((numPlots+2))) + 3)).toString() + 'px + 0.2vw)' , paddingBottom: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: '' }}>
                                    {this.props.occupancy ? 'Wkend Peak Avg' : (windowWidth / numPlots) < 650 ? 'Wkend Avg' : 'Weekend Avg'}
                                  </Typography>
                              </Grid>
                              <Tooltip title={<p style={{ fontSize: 14 }}> {s.prevDateLabel} </p>} placement='bottom-start' TransitionComponent={Zoom}>
                                <Grid xs={6} container item direction='row' justifyContent="flex-start" alignItems="stretch" style={{cursor: 'help', display: mode === 'Conversion' || mode === 'Dwell' || windowWidth / numPlots < 160 ? 'none' : '', color: s.countAvg.delta.avgWeekend >= 0 ? '#56993B' : '#BF3E37', fontWeight: 500, fontSize: 'calc(' + (Math.max(3, Math.round((startFontSize) / ((numPlots+2))) + 8)).toString() + 'px + 0.5vw)', backgroundColor: '', marginTop: 0, paddingLeft:'calc(' + (Math.max(4, Math.round((startFontSize) / ((numPlots+2))) + 2)).toString() + 'px + 0.2vw)', fontWeight: 500, backgroundColor: '' }}>                                    
                                    <Grid container  item direction='row' justifyContent="flex-start" alignItems="flex-end"  style={{height:'60%',backgroundColor:'',marginBottom:-4}}>
                                      <CountUp
                                        start={0}
                                        end={s.countAvg.delta.avgWeekend}
                                        duration={2.2}
                                        delay={0}
                                        separator=','
                                        decimals={0}
                                        decimal='.'
                                        prefix={s.countAvg.delta.avgWeekend >= 0 ? '+' : ''}
                                        suffix={'&#8239;<small>%</small>'}   
                                      >
                                        {({ countUpRef }) => (
                                          <div>
                                            <span ref={countUpRef} />
                                          </div>
                                        )}
                                      </CountUp>
                                      </Grid>
                                        <Typography noWrap style={{ height:'40%',color: subTitleColor, fontWeight: 400, display: '', fontSize: 'calc(' + (Math.max(4, Math.round((startFontSize) / ((numPlots+2))) + 2)).toString() + 'px + 0.2vw)', paddingTop: 0, paddingLeft: 0, paddingRight: 0, backgroundColor:'' }}>
                                          vs.&#8239;prev&#8239;&#9432;
                                        </Typography>
                                </Grid>
                              </Tooltip>
                              </Grid>
                              <Grid xs item container direction='row' justifyContent="flex-start" alignItems="stretch" style={{height:'100%',paddingLeft: '0', backgroundColor: '', minWidth: 80 }}>
                              <Grid xs={6} container item direction='row' justifyContent="flex-start" alignItems="stretch" style={{marginTop: 0, paddingLeft:'calc(' + (Math.max(4, Math.round((startFontSize) / ((numPlots+2))) + 4)).toString() + 'px + 0.2vw)', fontWeight: 500, fontSize: 'calc(' + (Math.max(4, Math.round((startFontSize) / ((numPlots+2))) + 10)).toString() + 'px + 0.6vw)', backgroundColor: '', }}>
                                <Grid container  item direction='row' justifyContent="flex-start" alignItems="flex-end"  style={{marginBottom:-3,height:'60%',backgroundColor:''}}>
                                <CountUp
                                    start={0}
                                    end={s.countAvg.current.avgWork >= 1000 ? s.countAvg.current.avgWork / 1000 : s.countAvg.current.avgWork}
                                    duration={2.2}
                                    delay={0}
                                    separator=','
                                    decimals={(s.countAvg.current.avgWork >= 1000 || mode === 'Conversion') ? 1 : 0}
                                    decimal='.'
                                    prefix=''
                                    suffix={s.countAvg.current.avgDaily >= 1000 ? '&#8239;<small>K</small>' : (mode === 'Conversion' ? '&#8239;<small>%</small>' : mode === 'Dwell' ? '&#8239;<small>sec</small>':'&#8239;')}
                                  >
                                    {({ countUpRef }) => (
                                      <div>
                                        <span ref={countUpRef} />
                                      </div>
                                    )}
                                  </CountUp>
                                  </Grid>
                                  <Typography noWrap style={{ fontWeight: 400, height:'40%',fontSize: 'calc(' + (Math.max(4, Math.round((startFontSize) / ((numPlots+2))) + 3)).toString() + 'px + 0.2vw)' , paddingBottom: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: '' }}>
                                  {this.props.occupancy ? 'Wkday Peak Avg' : (windowWidth / numPlots) < 650 ? 'Wkday Avg' : 'Weekday Avg'}
                                  </Typography>
                              </Grid>
                              <Tooltip title={<p style={{ fontSize: 14 }}> {s.prevDateLabel} </p>} placement='bottom-start' TransitionComponent={Zoom}>
                                <Grid xs={6} container item direction='row' justifyContent="flex-start" alignItems="stretch" style={{cursor: 'help', display: mode === 'Conversion' || mode === 'Dwell' || windowWidth / numPlots < 160 ? 'none' : '', color: s.countAvg.delta.avgWork >= 0 ? '#56993B' : '#BF3E37', fontWeight: 500, fontSize: 'calc(' + (Math.max(3, Math.round((startFontSize) / ((numPlots+2))) + 8)).toString() + 'px + 0.5vw)', backgroundColor: '', marginTop: 0, paddingLeft:'calc(' + (Math.max(4, Math.round((startFontSize) / ((numPlots+2))) + 2)).toString() + 'px + 0.2vw)', fontWeight: 500, backgroundColor: '' }}>                                    
                                    <Grid container  item direction='row' justifyContent="flex-start" alignItems="flex-end"  style={{height:'60%',backgroundColor:'',marginBottom:-4}}>
                                      <CountUp
                                        start={0}
                                        end={s.countAvg.delta.avgWork}
                                        duration={2.2}
                                        delay={0}
                                        separator=','
                                        decimals={0}
                                        decimal='.'
                                        prefix={s.countAvg.delta.avgWork >= 0 ? '+' : ''}
                                        suffix={'&#8239;<small>%</small>'}   
                                      >
                                        {({ countUpRef }) => (
                                          <div>
                                            <span ref={countUpRef} />
                                          </div>
                                        )}
                                      </CountUp>
                                      </Grid>
                                        <Typography noWrap style={{ height:'40%',color: subTitleColor, fontWeight: 400, display: '', fontSize: 'calc(' + (Math.max(4, Math.round((startFontSize) / ((numPlots+2))) + 2)).toString() + 'px + 0.2vw)', paddingTop: 0, paddingLeft: 0, paddingRight: 0, backgroundColor:'' }}>
                                          vs.&#8239;prev&#8239;&#9432;
                                        </Typography>
                                </Grid>
                              </Tooltip>
                              </Grid>
                            
                          </Grid>
                          

                          </Grid>
                          {Object.values(this.props.locationsObject['labelList']).indexOf(s.locationLabels[0]?.replace(vTypesRegex, '')) > -1 && windowWidth / numPlots >= 350 &&
                          <Grid  container item xs={2} hidden={numPlots>1} direction='row' justifyContent="space-around" alignItems="stretch" style={{height: (windowHeight * 0.13) - 4, minHeight: (windowHeight * 0.13) - 4 ,paddingLeft: '0', backgroundColor: '' }}>
                             {/* <div style={{width:'100%',height: (windowHeight * 0.12) - 4, minHeight: 77 ,paddingLeft: '0', backgroundColor: 'yellow' }}> */}
                              {/* <Grid hidden={Object.values(this.props.locationsObject['mapRegions']).length>0}>  */}
                              <img hidden={this.props.locationsObject['mapRegions'][s.locationLabels[0]]!=null} style={{ objectFit: 'cover',width:'100%',height:'100%'}} alt='loc Logo' src={getImage(this.props.client + '/' + s.locationLabels[0].replace('ä','a').replaceAll(/[\s\+\']+/g, '_') + '.jpg', false)} />
                              {/* </Grid> */}
                              {/* <Grid> */}
                                 {/* hidden={this.props.locationsObject['mapRegions'][s.locationLabels[0]]===null}> */}
                                {this.props.locationsObject['mapRegions'][s.locationLabels[0]]  &&
                                  <LoadScript
                                    googleMapsApiKey='AIzaSyDgSY4KoPydGmm1NYoyeIQfAa-aShZAAds'
                                    mapIds={['10324736f3dd8795']}
                                    // hidden={this.props.locationsObject['mapRegions'][s.locationLabels[0]]===null}
                                  >
                                    <GoogleMap
                                      mapContainerStyle={{ width: '100%', height: '120%' }}
                                      options={{ mapId: '10324736f3dd8795', mapTypeControl: false, 
                                        streetViewControl: false, fullscreenControl: false }}
                                      zoom={this.isMobile ? 16.2 : 16.8}
                                      center={this.props.locationsObject['mapRegions'][s.locationLabels[0]].position}
                                      // onLoad={this.onLoad}
                                    >
                                      {/* {Object.values(this.props.locationsObject['mapRegions'][s.locationLabels[0]]).map((marker, index) => (
                                        <Fragment key={index}> */}
                                          <Polygon
                                            paths={this.props.locationsObject['mapRegions'][s.locationLabels[0]].polygonCoordinates}
                                            options={{
                                              fillColor: getColor(s.locationLabels, idx, this.props.locationsObject, chartArray),
                                              fillOpacity: 0.3,
                                              strokeColor: darkenColor(getColor(s.locationLabels, idx, this.props.locationsObject, chartArray),30),
                                              strokeWeight: 2,
                                              strokeOpacity: 0.9,
                                              zIndex: (this.props.locationsObject['mapRegions'][s.locationLabels[0]].color === 'green') ? 2 : 1
                                            }}
                                          />
                                        {/* </Fragment> */}
                                      {/* ))} */}
                                    </GoogleMap>
                                  </LoadScript>
                              }
                              {/* </Grid> */}
                            </Grid>
                           }
                        </Grid>
                        
                        {/* </Fragment> */}
                      </Card>
                      
                    </ImageListItem>      
                  ]
                ))}
              </ImageList>
            }            
            {this.props.openTab === 1 && !vehicles && ((windowWidth + windowHeight) * 2 - windowHeight) / numPlots > 600 &&
              <ImageList gap={0} className={classes.gridList} cols={multDates ? 2 : numPlots} style={{ minHeight: 80,height:'100%', display: (this.props.gender && numPlots > 0) ? '' : 'none' }}>
                {chartArray.map((s, idx) => (
                  [
                    <ImageListItem key={'gd' + idx} style={{ minHeight: 77, height: '100%', display: (typeof (s.locationLabels[0]) != 'undefined') ? '' : 'none' }}>
                      <Card raised={false} style={{ minHeight: 77, marginRight:4, backgroundColor: 'white', marginBottom: 3, padding: '0px', display: (typeof (s.locationLabels[0]) != 'undefined') ? '' : '' }}>
                        <Grid container direction='column' justifyContent='flex-start' alignItems='stretch' style={{ height: (windowHeight * 0.13) - 4}}>
                          <Grid container item direction='row' justifyContent='flex-start' alignItems='center' style={{ position: 'relative', backgroundColor: '' }}>
                            <svg style={{ width: '100%', height: windowHeight * 0.03, minHeight: 27, position: 'relative', top: 0 }}>
                              <defs>
                                <pattern id="pattern-stripe"
                                  width="9" height="8"
                                  patternUnits="userSpaceOnUse"
                                  patternTransform="rotate(45)">
                                  <rect width="7" height="8" transform="translate(0,0)" fill="white"></rect>
                                </pattern>
                                <mask id="mask-stripe">
                                  <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe)" />
                                </mask>
                              </defs>
                              <rect fill={alpha(getColor(s.locationLabels, idx, this.props.locationsObject, chartArray), 0.4)}
                                mask={parseInt(s.name.substr(s.name.length - 3)[0]) > 1 ? "url(#mask-stripe)" : ""}
                                x="0"
                                y="0"
                                width="100%"
                                height="100%" />
                              <rect fill={alpha(getColor(s.locationLabels, idx, this.props.locationsObject, chartArray), 0.3)}
                                x="0"
                                y="0"
                                width="100%"
                                height="100%" />
                            </svg>
                            <Typography noWrap wrap='nowrap' style={{ marginTop: Math.min(-windowHeight * 0.03, -27), zIndex: 1, width: '100%', fontWeight: 600, fontSize: 'calc(' + (Math.max(7, Math.round(startFontSize / numPlots) - 4)).toString() + 'px + 0.2vw)', paddingTop: 2, paddingBottom: 2, paddingLeft: 10, marginBottom: 0 }}>
                              {s.locationLabels.join(', ')} {(multDates) ? ' (' + s.dateLabel + ')' : ''}
                            </Typography>      
                            <Grid container xs={12} item direction='row' justifyContent="flex-start" alignItems="stretch" style={{ paddingLeft: 'calc(0px + 0vw)', marginTop: 0, marginBottom: '0.0vh', height: (windowHeight * 0.13) + Math.min(-windowHeight * 0.03, -27) - 4, minHeight: 50, backgroundColor: '', paddingBottom: 0 }}>
                              <Grid key={Math.random()} item xs={(widthAdd + 0 + (windowWidth / (windowHeight / heightDiv)) ** ratioExp * windowWidth) / numPlots < col2 ? 12 : (widthAdd + 0 + (windowWidth / (windowHeight / heightDiv)) ** ratioExp * windowWidth) / numPlots < col3 ? 6 : (windowWidth) / numPlots < col4 ? 4 : 3} container alignItems="center" style={{ height: '100%', paddingLeft: '0', backgroundColor: '' }}>
                                <Grid container direction='row' justifyContent="center" alignItems="stretch" style={{ height: '100%', width: '100%', marginTop: 0, fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)', backgroundColor: '' }}>
                                  <Grid container direction='column' alignItems="center" justifyContent="center" style={{ height: '100%', width: '100%', paddingLeft: 0, backgroundColor: '' }}>
                                  <ResponsiveContainer width={'100%'} height={'100%'} minHeight={50}>
                                  <PieChart style={{ paddingTop: 0 }}>
                                  <Pie cx={'50%'} cy={10+(windowHeight / 21 + windowWidth / 90)} outerRadius={0+(windowHeight / 25 + windowWidth / 160) +10} innerRadius={0+(windowHeight / 28 + windowWidth / 190)+8} startAngle={180} endAngle={0} paddingAngle={3}
                                      fill={'#8884d8'} dataKey='value'
                                      data={(typeof s.genderAvg !== 'undefined' && typeof s.genderAvg.current.avgDaily !== 'undefined') ? s.genderAvg.current.avgDaily : []}
                                      labelLine={false} maxRadius={200} label={this.renderCustomizedLabel}>
                                      {
                                        (typeof s.genderAvg !== 'undefined' && typeof s.genderAvg.current.avgDaily !== 'undefined') ? s.genderAvg.current.avgDaily.map((entry, index) => <Cell key={'gld' + index} fill={GENDER_COLORS[index % GENDER_COLORS.length]} />) : []
                                      }
                                    </Pie>
                                  </PieChart>
                                </ResponsiveContainer>
                                  </Grid>
                                  {/* <Grid>
                                  <Grid container direction='column' alignItems="stretch" justifyContent="center" style={{display:mode==='Conversion' || mode==='Dwell'?'none':'' ,color:s.countAvg.delta.avgDaily>=0?'#56993B':'#BF3E37',fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)',height:'100%',backgroundColor:'red'}}>
                                  <CountUp
                                      start={0}
                                      end={ s.countAvg.delta.avgDaily }
                                      duration={2.2}
                                      delay={0}
                                      separator=','
                                      // decimals={(s.countAvg.current.avgWeekend >= 1000 || mode === 'Conversion') ? 1 : 0}
                                      decimal='.'
                                      prefix={s.countAvg.delta.avgDaily>=0?'+':''}
                                      suffix={ (mode === 'Conversion' ? '%' : '&#8239;' + '%'.small())}
                                    >
                                      {({ countUpRef }) => (
                                        <div>
                                          <span ref={countUpRef} />
                                        </div>
                                      )}
                                    </CountUp>
                                    <Typography noWrap style={{color:subTitleColor, fontWeight: 400, fontSize: 'calc(' + (startLabelFontSize - 3.5).toString() + 'px + 0.25vw)', paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                                      Prev Period
                                    </Typography>
                                  </Grid>
                                  </Grid> */}
                                </Grid>
                              </Grid>
                              <Grid key={Math.random()} item xs={(widthAdd + 0 + (windowWidth / (windowHeight / heightDiv)) ** ratioExp * windowWidth) / numPlots < col2 ? false : (widthAdd + 0 + (windowWidth / (windowHeight / heightDiv)) ** ratioExp * windowWidth) / numPlots < col3 ? 6 : (windowWidth) / numPlots < col4 ? 4 : 3} container alignItems="center" style={{ height: '100%', width: '100%', paddingLeft: '0', backgroundColor: '' }}>
                                <Grid container direction='row' justifyContent="flex-start" alignItems="stretch" style={{ height: '100%', width: '100%', marginTop: 0, fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)', backgroundColor: '' }}>
                                  <Grid container direction='column' alignItems="center" justifyContent="center" style={{ height: '100%', width: '100%', paddingLeft: 0, backgroundColor: '' }}>
                                  {/* <Grid container item xs key={Math.random()} style={{ minWidth: windowHeight / 4, paddingLeft: 0, paddingRight: 0, height: '100%' }}> */}
                                  <ResponsiveContainer width={'100%'} height={'100%'} minHeight={50}>
                                  <PieChart style={{ paddingTop: 0 }}>
                                  <Pie cx={'50%'} cy={10+(windowHeight / 21 + windowWidth / 90)} outerRadius={0+(windowHeight / 25 + windowWidth / 160) +10} innerRadius={0+(windowHeight / 28 + windowWidth / 190)+8} startAngle={180} endAngle={0} paddingAngle={3}
                                      fill={'#8884d8'} dataKey='value'
                                      data={(typeof s.genderAvg !== 'undefined' && typeof s.genderAvg.current.avgWeekend !== 'undefined') ? s.genderAvg.current.avgWeekend : []}
                                      labelLine={false} maxRadius={201} label={this.renderCustomizedLabel}>
                                      {
                                        (typeof s.genderAvg !== 'undefined' && typeof s.genderAvg.current.avgWeekend !== 'undefined') ? s.genderAvg.current.avgWeekend.map((entry, index) => <Cell key={'gld' + index} fill={GENDER_COLORS[index % GENDER_COLORS.length]} />) : []
                                      }
                                    </Pie>
                                  </PieChart>
                                </ResponsiveContainer>
                                  </Grid>
                                  {/* <Grid>
                                  <Grid container direction='column' alignItems="stretch" justifyContent="center" style={{display:mode==='Conversion' || mode==='Dwell'?'none':'' ,color:s.countAvg.delta.avgDaily>=0?'#56993B':'#BF3E37',fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)',height:'100%',backgroundColor:'red'}}>
                                  <CountUp
                                      start={0}
                                      end={ s.countAvg.delta.avgDaily }
                                      duration={2.2}
                                      delay={0}
                                      separator=','
                                      // decimals={(s.countAvg.current.avgWeekend >= 1000 || mode === 'Conversion') ? 1 : 0}
                                      decimal='.'
                                      prefix={s.countAvg.delta.avgDaily>=0?'+':''}
                                      suffix={ (mode === 'Conversion' ? '%' : '&#8239;' + '%'.small())}
                                    >
                                      {({ countUpRef }) => (
                                        <div>
                                          <span ref={countUpRef} />
                                        </div>
                                      )}
                                    </CountUp>
                                    <Typography noWrap style={{color:subTitleColor, fontWeight: 400, fontSize: 'calc(' + (startLabelFontSize - 3.5).toString() + 'px + 0.25vw)', paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                                      Prev Period
                                    </Typography>
                                  </Grid>
                                  </Grid> */}
                                </Grid>
                              </Grid>
                              <Grid key={Math.random()} item xs={(widthAdd + 0 + (windowWidth / (windowHeight / heightDiv)) ** ratioExp * windowWidth) / numPlots < col3 ? 4 : (windowWidth) / numPlots < col4 ? 4 : 3} container alignItems="center" style={{ height: '100%', width: '100%', paddingLeft: '0', backgroundColor: '' }}>
                                <Grid container direction='row' justifyContent="flex-start" alignItems="stretch" style={{ height: '100%', width: '100%', marginTop: 0, fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)', backgroundColor: '' }}>
                                  <Grid container direction='column' alignItems="center" justifyContent="center" style={{ height: '100%', width: '100%', paddingLeft: 0, backgroundColor: '' }}>
                                  {/* <Grid container item xs key={Math.random()} style={{ minWidth: windowHeight / 4, paddingLeft: 0, paddingRight: 0, height: '100%' }}> */}
                                  <ResponsiveContainer width={'100%'} height={'100%'} minHeight={50}>
                                  <PieChart style={{ paddingTop: 0 }}>
                                  <Pie cx={'50%'} cy={10+(windowHeight / 21 + windowWidth / 90)} outerRadius={0+(windowHeight / 25 + windowWidth / 160) +10} innerRadius={0+(windowHeight / 28 + windowWidth / 190)+8} startAngle={180} endAngle={0} paddingAngle={3}
                                      fill={'#8884d8'} dataKey='value'
                                      data={(typeof s.genderAvg !== 'undefined' && typeof s.genderAvg.current.avgWork !== 'undefined') ? s.genderAvg.current.avgWork : []}
                                      labelLine={false} maxRadius={202} label={this.renderCustomizedLabel}>
                                      {
                                        (typeof s.genderAvg !== 'undefined' && typeof s.genderAvg.current.avgWork !== 'undefined') ? s.genderAvg.current.avgWork.map((entry, index) => <Cell key={'gld' + index} fill={GENDER_COLORS[index % GENDER_COLORS.length]} />) : []
                                      }
                                    </Pie>
                                  </PieChart>
                                </ResponsiveContainer>
                                  </Grid>
                                  {/* <Grid>
                                  <Grid container direction='column' alignItems="stretch" justifyContent="center" style={{display:mode==='Conversion' || mode==='Dwell'?'none':'' ,color:s.countAvg.delta.avgDaily>=0?'#56993B':'#BF3E37',fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)',height:'100%',backgroundColor:'red'}}>
                                  <CountUp
                                      start={0}
                                      end={ s.countAvg.delta.avgDaily }
                                      duration={2.2}
                                      delay={0}
                                      separator=','
                                      // decimals={(s.countAvg.current.avgWeekend >= 1000 || mode === 'Conversion') ? 1 : 0}
                                      decimal='.'
                                      prefix={s.countAvg.delta.avgDaily>=0?'+':''}
                                      suffix={ (mode === 'Conversion' ? '%' : '&#8239;' + '%'.small())}
                                    >
                                      {({ countUpRef }) => (
                                        <div>
                                          <span ref={countUpRef} />
                                        </div>
                                      )}
                                    </CountUp>
                                    <Typography noWrap style={{color:subTitleColor, fontWeight: 400, fontSize: 'calc(' + (startLabelFontSize - 3.5).toString() + 'px + 0.25vw)', paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                                      Prev Period
                                    </Typography>
                                  </Grid>
                                  </Grid> */}
                                </Grid>
                              </Grid>
                              <Grid item xs={(windowWidth / numPlots) / numPlots < col4 ? false : 3} style={{ display: (windowWidth) / numPlots >= col4 ? '' : 'none', paddingLeft: '0', backgroundColor: '' }}>
                                <Grid container direction='row' justifyContent="flex-start" alignItems="stretch" style={{ height: '100%', paddingLeft: '0', marginTop: 0, fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)' }}>
                                  <Grid container direction='column' alignItems="stretch" justifyContent="center" style={{ paddingLeft: 'calc(3vw-10px)', height: '100%', width: '50%', backgroundColor: '' }}>
                                    <CountUp
                                      start={0}
                                      // end={typeof s.busiestFemaleDay !== 'undefined' ? s.busiestFemaleDay + 28 : 0}
                                      end={typeof s.busiestObj?.busiestFemaleDay !== 'undefined' ? s.busiestObj?.busiestFemaleDay + 28 : 0}
                                      duration={2.2}
                                      delay={0}
                                      separator=''
                                      decimals={0}
                                      decimal='.'
                                      prefix=''
                                      suffix=''
                                      formattingFn={(n) => {
                                        return ((numPlots >= 2) ? weekdayLookup[n].substr(0, 3) : weekdayLookup[n]);
                                      }}
                                    >
                                      {({ countUpRef }) => (
                                        <div>
                                          <span ref={countUpRef} style={{ color: colorsWomen[0] }} />
                                        </div>
                                      )}
                                    </CountUp>
                                    <Typography noWrap style={{ fontWeight: 400, fontSize: 'calc(' + (startLabelFontSize - 1).toString() + 'px + 0.25vw)', marginTop: 0 }}>
                                      Busiest Female Day
                                    </Typography>
                                  </Grid>
                                  <Grid container direction='column' alignItems="stretch" justifyContent="center" style={{ paddingLeft: 'calc(3vw-10px)', display: windowWidth / numPlots >= 800 ? '' : 'none', fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)', height: '100%', width: '50%', backgroundColor: '' }}>
                                    <CountUp
                                      start={0}
                                      // end={typeof s.busiestMaleDay !== 'undefined' ? s.busiestMaleDay + 28 : 0}
                                      end={typeof s.busiestObj?.busiestMaleDay !== 'undefined' ? s.busiestObj?.busiestMaleDay + 28 : 0}
                                      duration={2.2}
                                      delay={0}
                                      separator=''
                                      decimals={0}
                                      decimal='.'
                                      prefix=''
                                      suffix=''
                                      formattingFn={(n) => {
                                        return ((numPlots >= 2) ? weekdayLookup[n].substr(0, 3) : weekdayLookup[n]);
                                      }}
                                    >
                                      {({ countUpRef }) => (
                                        <div>
                                          <span ref={countUpRef} style={{ color: colorsMen[0] }} />
                                        </div>
                                      )}
                                    </CountUp>
                                    <Typography noWrap style={{ fontWeight: 400, fontSize: 'calc(' + (startLabelFontSize - 1).toString() + 'px + 0.25vw)', marginTop: 0 }}>
                                      Busiest Male Day
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </ImageListItem>
                  ]
                ))}
              </ImageList>
            }
            {this.props.openTab === 2 && !vehicles && ((windowWidth + windowHeight) * 2 - windowHeight) / numPlots > 600 &&
              <ImageList gap={0} className={classes.gridList} cols={multDates ? 2 : numPlots} style={{minHeight: 80, height: '100%', display: (this.props.age && numPlots > 0) ? '' : 'none' }}>
                {chartArray.map((s, idx) => (
                  [
                    <ImageListItem key={'gender' + idx} style={{ minHeight: 77, height: '100%', display: (typeof (s.locationLabels[0]) != 'undefined') ? '' : 'none' }}>
                      <Card raised={false} style={{ minHeight: 77, marginRight:4, marginBottom: 3, backgroundColor: 'white', padding: '0px', display: (typeof (s.locationLabels[0]) != 'undefined') ? '' : '' }}>
                        <Grid container direction='column' justifyContent='flex-start' alignItems='stretch' style={{ height: windowHeight * 0.13 -4 }}>
                          <Grid container item direction='row' justifyContent='flex-start' alignItems='center' style={{ position: 'relative' }}>
                            <svg style={{ width: '100%', height: windowHeight * 0.03, minHeight: 27, position: 'relative', top: 0 }}>
                              <defs>
                                <pattern id="pattern-stripe2"
                                  width="9" height="8"
                                  patternUnits="userSpaceOnUse"
                                  patternTransform="rotate(45)">
                                  <rect width="7" height="8" transform="translate(0,0)" fill="white"></rect>
                                </pattern>
                                <mask id="mask-stripe2">
                                  <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe2)" />
                                </mask>
                              </defs>
                              <rect fill={alpha(getColor(s.locationLabels, idx, this.props.locationsObject, chartArray), 0.4)}
                                mask={parseInt(s.name.substr(s.name.length - 3)[0]) > 1 ? "url(#mask-stripe2)" : ""}
                                x="0"
                                y="0"
                                width="100%"
                                height="100%" />
                              <rect fill={alpha(getColor(s.locationLabels, idx, this.props.locationsObject, chartArray), 0.3)}
                                x="0"
                                y="0"
                                width="100%"
                                height="100%" />
                            </svg>
                            <Typography noWrap wrap='nowrap' style={{ marginTop: Math.min(-windowHeight * 0.03, -27), zIndex: 1, width: '100%', fontWeight: 600, fontSize: 'calc(' + (Math.max(7, Math.round(startFontSize / numPlots) - 4)).toString() + 'px + 0.2vw)', paddingTop: 2, paddingBottom: 2, paddingLeft: 10, marginBottom: 0 }}>
                              {s.locationLabels.join(', ')} {(multDates) ? ' (' + s.dateLabel + ')' : ''}
                            </Typography>
                            <Grid container xs={12} item direction='row' justifyContent="flex-start" alignItems='stretch' style={{ marginTop: 0, marginBottom:'0.0vh', height: (windowHeight * 0.13) + Math.min(-windowHeight * 0.03, -27) - 4, minHeight: 50, backgroundColor: '', paddingBottom: 0 }}>
                              <Grid key={Math.random()} item xs={(widthAdd + 0 + (windowWidth / (windowHeight / heightDiv)) ** ratioExp * windowWidth) / numPlots < col2age ? 12 : (widthAdd + 0 + (windowWidth / (windowHeight / heightDiv)) ** ratioExp * windowWidth) / numPlots < col3age ? 6 : (windowWidth) / numPlots < col4age ? 4 : 3} container alignItems="center" style={{ height: '100%', paddingLeft: '0', backgroundColor: '' }}>
                              {/* <Grid key={Math.random()} item xs={windowWidth<300?12:windowWidth<500?6:windowWidth<900?4:3} container alignItems="center" style={{height:'100%',width:'100%',paddingLeft: '0', backgroundColor:''}}> */}
                                <Grid container direction='row' justifyContent="flex-start" alignItems="stretch" style={{ paddingLeft: 'calc(0px + 0.0vw)', height: '100%', width: '100%', marginTop: 0, fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)', backgroundColor: '' }}>
                                  <Grid container direction='column' alignItems="center" justifyContent="center" style={{ height: '100%', width: '100%', paddingLeft: 0, backgroundColor: '' }}>
                                  <ResponsiveContainer width={'100%'} height={'100%'} minHeight={50}>
                                  <PieChart style={{ paddingTop: 0 }}>
                                  <Pie cx={'50%'} cy={9+(windowHeight / 20 + windowWidth / 90)} outerRadius={8+(windowHeight / 20 + windowWidth / 110) } innerRadius={7+(windowHeight / 23 + windowWidth / 140)} startAngle={180} endAngle={0} paddingAngle={3}
                                      fill={'#8884d8'} dataKey='value'
                                      data={(typeof s.ageAvg !== 'undefined' && typeof s.ageAvg.current.avgDaily !== 'undefined') ? s.ageAvg.current.avgDaily : []}
                                      labelLine={false} maxRadius={200} label={this.renderCustomizedLabelAge}>
                                      {
                                        (typeof s.ageAvg !== 'undefined' && typeof s.ageAvg.current.avgDaily !== 'undefined') ? s.ageAvg.current.avgDaily.map((entry, index) => <Cell key={'gpd' + index} fill={AGE_COLORS[index % AGE_COLORS.length]} />) : []
                                      }
                                    </Pie>
                                  </PieChart>
                                </ResponsiveContainer>
                                  </Grid>
                                  {/* <Grid>
                                  <Grid container direction='column' alignItems="stretch" justifyContent="center" style={{display:mode==='Conversion' || mode==='Dwell'?'none':'' ,color:s.countAvg.delta.avgDaily>=0?'#56993B':'#BF3E37',fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)',height:'100%',backgroundColor:'red'}}>
                                  <CountUp
                                      start={0}
                                      end={ s.countAvg.delta.avgDaily }
                                      duration={2.2}
                                      delay={0}
                                      separator=','
                                      // decimals={(s.countAvg.current.avgWeekend >= 1000 || mode === 'Conversion') ? 1 : 0}
                                      decimal='.'
                                      prefix={s.countAvg.delta.avgDaily>=0?'+':''}
                                      suffix={ (mode === 'Conversion' ? '%' : '&#8239;' + '%'.small())}
                                    >
                                      {({ countUpRef }) => (
                                        <div>
                                          <span ref={countUpRef} />
                                        </div>
                                      )}
                                    </CountUp>
                                    <Typography noWrap style={{color:subTitleColor, fontWeight: 400, fontSize: 'calc(' + (startLabelFontSize - 3.5).toString() + 'px + 0.25vw)', paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                                      Prev Period
                                    </Typography>
                                  </Grid>
                                  </Grid> */}
                                </Grid>
                              </Grid>
                              {/* <Grid key={Math.random()} item xs={windowWidth<300?0:windowWidth<500?6:windowWidth<900?4:3} container alignItems="center" style={{height:'100%',width:'100%',paddingLeft: '0', backgroundColor:''}}> */}
                              <Grid key={Math.random()} item xs={(widthAdd + 0 + (windowWidth / (windowHeight / heightDiv)) ** ratioExp * windowWidth) / numPlots < col2age ? false : (widthAdd + 0 + (windowWidth / (windowHeight / heightDiv)) ** ratioExp * windowWidth) / numPlots < col3age ? 6 : (windowWidth) / numPlots < col4age ? 4 : 3} container alignItems="center" style={{ height: '100%', width: '100%', paddingLeft: '0', backgroundColor: '' }}>
                                <Grid container direction='row' justifyContent="flex-start" alignItems="stretch" style={{ height: '100%', width: '100%', marginTop: 0, fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)', backgroundColor: '' }}>
                                  <Grid container direction='column' alignItems="center" justifyContent="center" style={{ height: '100%', width: '100%', paddingLeft: 0, backgroundColor: '' }}>
                                  {/* <Grid container item xs key={Math.random()} style={{ minWidth: windowHeight / 4, paddingLeft: 0, paddingRight: 0, height: '100%' }}> */}
                                  <ResponsiveContainer width={'100%'} height={'100%'} minHeight={50}>
                                  <PieChart style={{ paddingTop: 0 }}>
                                  <Pie cx={'50%'} cy={9+(windowHeight / 20 + windowWidth / 90)} outerRadius={8+(windowHeight / 20 + windowWidth / 110) } innerRadius={7+(windowHeight / 23 + windowWidth / 140)} startAngle={180} endAngle={0} paddingAngle={3}
                                      fill={'#8884d8'} dataKey='value'
                                      data={(typeof s.ageAvg !== 'undefined' && typeof s.ageAvg.current.avgWeekend !== 'undefined') ? s.ageAvg.current.avgWeekend : []}
                                      labelLine={false} maxRadius={201} label={this.renderCustomizedLabelAge}>
                                      {
                                        (typeof s.ageAvg !== 'undefined' && typeof s.ageAvg.current.avgWeekend !== 'undefined') ? s.ageAvg.current.avgWeekend.map((entry, index) => <Cell key={'gpw' + index} fill={AGE_COLORS[index % AGE_COLORS.length]} />) : []
                                      }
                                    </Pie>
                                  </PieChart>
                                </ResponsiveContainer>
                                  </Grid>
                                  {/* <Grid>
                                  <Grid container direction='column' alignItems="stretch" justifyContent="center" style={{display:mode==='Conversion' || mode==='Dwell'?'none':'' ,color:s.countAvg.delta.avgDaily>=0?'#56993B':'#BF3E37',fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)',height:'100%',backgroundColor:'red'}}>
                                  <CountUp
                                      start={0}
                                      end={ s.countAvg.delta.avgDaily }
                                      duration={2.2}
                                      delay={0}
                                      separator=','
                                      // decimals={(s.countAvg.current.avgWeekend >= 1000 || mode === 'Conversion') ? 1 : 0}
                                      decimal='.'
                                      prefix={s.countAvg.delta.avgDaily>=0?'+':''}
                                      suffix={ (mode === 'Conversion' ? '%' : '&#8239;' + '%'.small())}
                                    >
                                      {({ countUpRef }) => (
                                        <div>
                                          <span ref={countUpRef} />
                                        </div>
                                      )}
                                    </CountUp>
                                    <Typography noWrap style={{color:subTitleColor, fontWeight: 400, fontSize: 'calc(' + (startLabelFontSize - 3.5).toString() + 'px + 0.25vw)', paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                                      Prev Period
                                    </Typography>
                                  </Grid>
                                  </Grid> */}
                                </Grid>
                              </Grid>
                              {/* <Grid key={Math.random()}  item xs={windowWidth<300?0:windowWidth<900?4:3} container alignItems="center" style={{height:'100%',width:'100%',paddingLeft: '0', backgroundColor:''}}> */}
                              <Grid key={Math.random()} item xs={(widthAdd + 0 + (windowWidth / (windowHeight / heightDiv)) ** ratioExp * windowWidth) / numPlots < col3age ? 4 : (windowWidth) / numPlots < col4age ? 4 : 3} container alignItems="center" style={{ height: '100%', width: '100%', paddingLeft: '0', backgroundColor: '' }}>
                                <Grid container direction='row' justifyContent="flex-start" alignItems="stretch" style={{ height: '100%', width: '100%', marginTop: 0, fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)', backgroundColor: '' }}>
                                  <Grid container direction='column' alignItems="center" justifyContent="center" style={{ height: '100%', width: '100%', paddingLeft: 0, backgroundColor: '' }}>
                                  {/* <Grid container item xs key={Math.random()} style={{ minWidth: windowHeight / 4, paddingLeft: 0, paddingRight: 0, height: '100%' }}> */}
                                  <ResponsiveContainer width={'100%'} height={'100%'} minHeight={50}>
                                  <PieChart style={{ paddingTop: 0 }}>
                                  <Pie cx={'50%'} cy={9+(windowHeight / 20 + windowWidth / 90)} outerRadius={8+(windowHeight / 20 + windowWidth / 110) } innerRadius={7+(windowHeight / 23 + windowWidth / 140)} startAngle={180} endAngle={0} paddingAngle={3}
                                      fill={'#8884d8'} dataKey='value'
                                      data={(typeof s.ageAvg !== 'undefined' && typeof s.ageAvg.current.avgWork !== 'undefined') ? s.ageAvg.current.avgWork : []}
                                      labelLine={false} maxRadius={202} label={this.renderCustomizedLabelAge}>
                                      {
                                        (typeof s.ageAvg !== 'undefined' && typeof s.ageAvg.current.avgWork !== 'undefined') ? s.ageAvg.current.avgWork.map((entry, index) => <Cell key={'gpwo' + index} fill={AGE_COLORS[index % AGE_COLORS.length]} />) : []
                                      }
                                    </Pie>
                                  </PieChart>
                                </ResponsiveContainer>
                                  </Grid>
                                  {/* <Grid>
                                  <Grid container direction='column' alignItems="stretch" justifyContent="center" style={{display:mode==='Conversion' || mode==='Dwell'?'none':'' ,color:s.countAvg.delta.avgDaily>=0?'#56993B':'#BF3E37',fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)',height:'100%',backgroundColor:'red'}}>
                                  <CountUp
                                      start={0}
                                      end={ s.countAvg.delta.avgDaily }
                                      duration={2.2}
                                      delay={0}
                                      separator=','
                                      // decimals={(s.countAvg.current.avgWeekend >= 1000 || mode === 'Conversion') ? 1 : 0}
                                      decimal='.'
                                      prefix={s.countAvg.delta.avgDaily>=0?'+':''}
                                      suffix={ (mode === 'Conversion' ? '%' : '&#8239;' + '%'.small())}
                                    >
                                      {({ countUpRef }) => (
                                        <div>
                                          <span ref={countUpRef} />
                                        </div>
                                      )}
                                    </CountUp>
                                    <Typography noWrap style={{color:subTitleColor, fontWeight: 400, fontSize: 'calc(' + (startLabelFontSize - 3.5).toString() + 'px + 0.25vw)', paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                                      Prev Period
                                    </Typography>
                                  </Grid>
                                  </Grid> */}
                                </Grid>
                              </Grid>
                              <Grid item xs={(windowWidth / numPlots) / numPlots < col4age ? false : 3} style={{ display: (windowWidth) / numPlots >= col4age ? '' : 'none', paddingLeft: '0', backgroundColor: '' }}>
                              {/* <Grid item xs={3}  style={{display:windowWidth<900?'none':'', paddingLeft: '0', backgroundColor:''}}> */}
                                <Grid container direction='row' justifyContent="flex-start" alignItems="stretch" style={{ height: '100%', paddingLeft: '0', marginTop: 0, fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)' }}>
                                  <Grid container direction='column' alignItems="stretch" justifyContent="center" style={{ height: '100%', width: '50%', backgroundColor: '' }}>
                                    <CountUp
                                      start={0}
                                      // end={typeof s.busiestChildDay !== 'undefined' ? s.busiestChildDay + 28 : 0}
                                      end={typeof s.busiestObj?.busiestChildDay !== 'undefined' ? s.busiestObj.busiestChildDay + 28 : 0}
                                      duration={2.2}
                                      delay={0}
                                      separator=''
                                      decimals={0}
                                      decimal='.'
                                      prefix=''
                                      suffix=''
                                      formattingFn={(n) => {
                                        return ((numPlots >= 2) ? weekdayLookup[n].substr(0, 3) : weekdayLookup[n]);
                                      }}
                                    >
                                      {({ countUpRef }) => (
                                        <div>
                                          <span ref={countUpRef} style={{ color: colorsChildren[0] }} />
                                        </div>
                                      )}
                                    </CountUp>
                                    <Typography noWrap style={{ fontWeight: 400, fontSize: 'calc(' + (startLabelFontSize - 1).toString() + 'px + 0.25vw)', marginTop: 0 }}>
                                      Busiest Child Day
                                    </Typography>
                                  </Grid>
                                  <Grid container direction='column' alignItems="stretch" justifyContent="center" style={{display:mode==='Conversion' || mode==='Dwell'?'':'',fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)',height:'100%',width:'50%',backgroundColor:''}}>
                                    <CountUp
                                      start={0}
                                      // end={typeof s.busiestSeniorDay !== 'undefined' ? s.busiestSeniorDay + 28 : 0}
                                      end={typeof s.busiestObj?.busiestSeniorDay !== 'undefined' ? s.busiestObj?.busiestSeniorDay + 28 : 0}
                                      duration={2.2}
                                      delay={0}
                                      separator=''
                                      decimals={0}
                                      decimal='.'
                                      prefix=''
                                      suffix=''
                                      formattingFn={(n) => {
                                        return ((numPlots >= 2) ? weekdayLookup[n].substr(0, 3) : weekdayLookup[n]);
                                      }}
                                    >
                                      {({ countUpRef }) => (
                                        <div>
                                          <span ref={countUpRef} style={{ color: colorsSeniors[0] }} />
                                        </div>
                                      )}
                                    </CountUp>
                                    <Typography noWrap style={{ fontWeight: 400, fontSize: 'calc(' + (startLabelFontSize - 1).toString() + 'px + 0.25vw)', marginTop: 0 }}>
                                      Busiest Senior Day
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* <Grid container direction='row' justifyContent='flex-start' alignItems='center' style={{ width: '100%', paddingLeft: '1vw', marginTop: 0, height: (windowHeight * 0.15) + Math.min(-windowHeight * 0.025, -16) - 4, minHeight: 50, backgroundColor: '', paddingBottom: 0 }}>
                              <Grid container item xs key={Math.random()} style={{ minWidth: windowHeight / 3.5, paddingLeft: 0, paddingRight: 0, height: '100%' }}>
                                <ResponsiveContainer width={'100%'} height={'100%'} minHeight={50}>
                                  <PieChart style={{ paddingTop: 3 }}>
                                    <Pie cx={(windowHeight / 7.2)} cy={'82%'} outerRadius={(windowHeight / 14) / (0.85 + numPlots * 0.05)} innerRadius={(windowHeight / 18) / (0.85 + numPlots * 0.05)} startAngle={180} endAngle={0} paddingAngle={5}
                                      fill={'#8884d8'} dataKey='value'
                                      data={(typeof s.ageAvg !== 'undefined' && typeof s.ageAvg.current.avgDaily !== 'undefined') ? s.ageAvg.current.avgDaily : []}
                                      labelLine={false} maxRadius={200} label={this.renderCustomizedLabelAge}>
                                      {
                                        (typeof s.ageAvg !== 'undefined' && typeof s.ageAvg.current.avgDaily !== 'undefined') ? s.ageAvg.current.avgDaily.map((entry, index) => <Cell key={'gpd' + index} fill={AGE_COLORS[index % AGE_COLORS.length]} />) : []
                                      }
                                    </Pie>
                                  </PieChart>
                                </ResponsiveContainer>
                              </Grid>
                              <Grid container item xs key={Math.random()} style={{ minWidth: windowHeight / 3.5, display: (numPlots > 3 || windowWidth < 500) ? '' : '', paddingLeft: 0, paddingRight: 0, height: '100%' }}>
                                <ResponsiveContainer width={'100%'} height={'100%'} minHeight={50}>
                                  <PieChart style={{ paddingTop: 3 }}>
                                    <Pie cx={(windowHeight / 7.2)} cy={'82%'} outerRadius={(windowHeight / 14) / (0.85 + numPlots * 0.05)} innerRadius={(windowHeight / 18) / (0.85 + numPlots * 0.05)} startAngle={180} endAngle={0} paddingAngle={5}
                                      fill={'#8884d8'} dataKey='value'
                                      data={(typeof s.ageAvg !== 'undefined' && typeof s.ageAvg.current.avgWeekend !== 'undefined') ? s.ageAvg.current.avgWeekend : []}
                                      labelLine={false} maxRadius={201} label={this.renderCustomizedLabelAge}>
                                      {
                                        (typeof s.ageAvg !== 'undefined' && typeof s.ageAvg.current.avgWeekend !== 'undefined') ? s.ageAvg.current.avgWeekend.map((entry, index) => <Cell key={'gpw' + index} fill={AGE_COLORS[index % AGE_COLORS.length]} />) : []
                                      }
                                    </Pie>
                                  </PieChart>
                                </ResponsiveContainer>
                              </Grid>
                              <Grid container item xs key={Math.random()} style={{ minWidth: windowHeight / 3.5, display: (numPlots > 2 || windowWidth < 800) ? '' : '', paddingLeft: 0, paddingRight: 0, height: '100%' }}>
                                <ResponsiveContainer width={'100%'} height={'100%'} minHeight={50}>
                                  <PieChart style={{ paddingTop: 3 }}>
                                    <Pie cx={(windowHeight / 7.2)} cy={'82%'} outerRadius={(windowHeight / 14) / (0.85 + numPlots * 0.05)} innerRadius={(windowHeight / 18) / (0.85 + numPlots * 0.05)} startAngle={180} endAngle={0} paddingAngle={5}
                                      fill={'#8884d8'} dataKey='value'
                                      data={(typeof s.ageAvg !== 'undefined' && typeof s.ageAvg.current.avgWork !== 'undefined') ? s.ageAvg.current.avgWork : []}
                                      labelLine={false} maxRadius={202} label={this.renderCustomizedLabelAge}>
                                      {
                                        (typeof s.ageAvg !== 'undefined' && typeof s.ageAvg.current.avgWork !== 'undefined') ? s.ageAvg.current.avgWork.map((entry, index) => <Cell key={'gpwo' + index} fill={AGE_COLORS[index % AGE_COLORS.length]} />) : []
                                      }
                                    </Pie>
                                  </PieChart>
                                </ResponsiveContainer>
                              </Grid>
                              <Grid container item xs direction='column' justifyContent='center' alignItems='flex-start' style={{ minWidth: windowHeight / 6 }}>
                                <Grid item style={{ fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)', paddingLeft: 8, paddingRight: 8, paddingTop: 0 }}>
                                  <CountUp
                                    start={0}
                                    end={typeof s.busiestChildDay !== 'undefined' ? s.busiestChildDay + 28 : 0}
                                    duration={2.2}
                                    delay={0}
                                    separator=''
                                    decimals={0}
                                    decimal='.'
                                    prefix=''
                                    suffix=''
                                    formattingFn={(n) => {
                                      return ((numPlots >= 2) ? weekdayLookup[n].substr(0, 3) : weekdayLookup[n]);
                                    }}
                                  >
                                    {({ countUpRef }) => (
                                      <div>
                                        <span ref={countUpRef} style={{ color: colorsChildren[0] }} />
                                      </div>
                                    )}
                                  </CountUp>
                                </Grid>
                                <Typography style={{ backgroundColor: 'white', fontWeight: 400, fontSize: 'calc(' + (startLabelFontSize - numPlots).toString() + 'px + 0.4vw)', paddingBottom: 0, paddingLeft: 8, paddingRight: 8 }}>
                                  Busiest Child Day
                                </Typography>
                              </Grid>
                              <Grid container item xs direction='column' justifyContent='center' alignItems='flex-start' style={{ minWidth: windowHeight / 6 }}>
                                <Grid item style={{ fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)', paddingLeft: 8, paddingRight: 8, paddingTop: 0 }}>
                                  <CountUp
                                    start={0}
                                    end={typeof s.busiestSeniorDay !== 'undefined' ? s.busiestSeniorDay + 28 : 0}
                                    duration={2.2}
                                    delay={0}
                                    separator=''
                                    decimals={0}
                                    decimal='.'
                                    prefix=''
                                    suffix=''
                                    formattingFn={(n) => {
                                      return ((numPlots >= 2) ? weekdayLookup[n].substr(0, 3) : weekdayLookup[n]);
                                    }}
                                  >
                                    {({ countUpRef }) => (
                                      <div>
                                        <span ref={countUpRef} style={{ color: colorsSeniors[0] }} />
                                      </div>
                                    )}
                                  </CountUp>
                                </Grid>
                                <Typography style={{ backgroundColor: 'white', fontWeight: 400, fontSize: 'calc(' + (startLabelFontSize - numPlots).toString() + 'px + 0.4vw)', paddingBottom: 0, paddingLeft: 8, paddingRight: 8 }}>
                                  Busiest Senior Day
                                </Typography>
                              </Grid>
                            </Grid> */}
                          </Grid>
                        </Grid>
                      </Card>
                    </ImageListItem>
                  ]
                ))}
              </ImageList>
            }

            {this.props.openTab === 1 && vehicles && ((windowWidth + windowHeight) * 2 - windowHeight) / numPlots > 600 &&
              <ImageList gap={0} className={classes.gridList} cols={multDates ? 2 : numPlots} style={{ minHeight: 80, height: '100%', display: (this.props.gender && numPlots > 0) ? '' : 'none' }}>
                {chartArray.map((s, idx) => (
                  [
                    <ImageListItem key={'vehicles' + idx} style={{ minHeight: 77, height: '100%', display: (typeof (s.locationLabels[0]) != 'undefined') ? '' : 'none' }}>
                      <Card raised={false} style={{ minHeight: 77, marginRight: 4, marginBottom: 3, backgroundColor: 'white', padding: '0px', display: (typeof (s.locationLabels[0]) != 'undefined') ? '' : '' }}>
                        <Grid container direction='column' justifyContent='flex-start' alignItems='stretch' style={{ height: windowHeight * 0.13 - 4 }}>
                          <Grid container item direction='row' justifyContent='flex-start' alignItems='center' style={{ position: 'relative' }}>
                            <svg style={{ width: '100%', height: windowHeight * 0.03, minHeight: 27, position: 'relative', top: 0 }}>
                              <defs>
                                <pattern id="pattern-stripe2"
                                  width="9" height="8"
                                  patternUnits="userSpaceOnUse"
                                  patternTransform="rotate(45)">
                                  <rect width="7" height="8" transform="translate(0,0)" fill="white"></rect>
                                </pattern>
                                <mask id="mask-stripe2">
                                  <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe2)" />
                                </mask>
                              </defs>
                              <rect fill={alpha(getColor(s.locationLabels, idx, this.props.locationsObject, chartArray), 0.4)}
                                mask={parseInt(s.name.substr(s.name.length - 3)[0]) > 1 ? "url(#mask-stripe2)" : ""}
                                x="0"
                                y="0"
                                width="100%"
                                height="100%" />
                              <rect fill={alpha(getColor(s.locationLabels, idx, this.props.locationsObject, chartArray), 0.3)}
                                x="0"
                                y="0"
                                width="100%"
                                height="100%" />
                            </svg>
                            <Typography noWrap wrap='nowrap' style={{ marginTop: Math.min(-windowHeight * 0.03, -27), zIndex: 1, width: '100%', fontWeight: 600, fontSize: 'calc(' + (Math.max(7, Math.round(startFontSize / numPlots) - 4)).toString() + 'px + 0.2vw)', paddingTop: 2, paddingBottom: 2, paddingLeft: 10, marginBottom: 0 }}>
                              {s.locationLabels.join(', ')} {(multDates) ? ' (' + s.dateLabel + ')' : ''}
                            </Typography>

                            <Grid container xs={12} item direction='row' justifyContent='flex-start' alignItems='stretch' style={{ marginTop: 0, marginBottom: '0.0vh', height: (windowHeight * 0.13) + Math.min(-windowHeight * 0.03, -27) - 4, minHeight: 50, backgroundColor: '', paddingBottom: 0 }}>

                              <Grid key={Math.random()} item xs={(widthAdd + 0 + (windowWidth / (windowHeight / heightDiv)) ** ratioExp * windowWidth) / numPlots < col2age ? 12 : (widthAdd + 0 + (windowWidth / (windowHeight / heightDiv)) ** ratioExp * windowWidth) / numPlots < col3age ? 6 : (windowWidth) / numPlots < col4age ? 4 : 3} container alignItems='center' style={{ height: '100%', paddingLeft: '0', backgroundColor: '' }}>
                                <Grid container direction='row' justifyContent='flex-start' alignItems='stretch' style={{ paddingLeft: 'calc(0px + 0.0vw)', height: '100%', width: '100%', marginTop: 0, fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)', backgroundColor: '' }}>
                                  <Grid container direction='column' alignItems='center' justifyContent='center' style={{ height: '100%', width: '100%', paddingLeft: 0, backgroundColor: '' }}>
                                    <ResponsiveContainer width={'100%'} height={'100%'} minHeight={50}>
                                      <PieChart style={{ paddingTop: 0 }}>
                                        <Pie
                                          cx={'50%'}
                                          cy={9 + (windowHeight / 20 + windowWidth / 90)}
                                          outerRadius={8 + (windowHeight / 20 + windowWidth / 110)}
                                          innerRadius={7 + (windowHeight / 23 + windowWidth / 140)}
                                          startAngle={180}
                                          endAngle={0}
                                          paddingAngle={3}
                                          fill={'#8884d8'}
                                          dataKey='value'
                                          data={(typeof s.vehicleAvg !== 'undefined' && typeof s.vehicleAvg.current.avgDaily !== 'undefined') ? s.vehicleAvg.current.avgDaily : []}
                                          labelLine={false}
                                          maxRadius={200}
                                          label={this.renderCustomizedLabelVehicle}
                                        >
                                          {
                                            (typeof s.vehicleAvg !== 'undefined' && typeof s.vehicleAvg.current.avgDaily !== 'undefined') ? s.vehicleAvg.current.avgDaily.map((entry, index) => <Cell key={'gpd' + index} fill={VEHICLE_COLORS[index % VEHICLE_COLORS.length]} />) : []
                                          }
                                        </Pie>
                                      </PieChart>
                                    </ResponsiveContainer>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid key={Math.random()} item xs={(widthAdd + 0 + (windowWidth / (windowHeight / heightDiv)) ** ratioExp * windowWidth) / numPlots < col2age ? false : (widthAdd + 0 + (windowWidth / (windowHeight / heightDiv)) ** ratioExp * windowWidth) / numPlots < col3age ? 6 : (windowWidth) / numPlots < col4age ? 4 : 3} container alignItems='center' style={{ height: '100%', width: '100%', paddingLeft: '0', backgroundColor: '' }}>
                                <Grid container direction='row' justifyContent='flex-start' alignItems='stretch' style={{ height: '100%', width: '100%', marginTop: 0, fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)', backgroundColor: '' }}>
                                  <Grid container direction='column' alignItems='center' justifyContent='center' style={{ height: '100%', width: '100%', paddingLeft: 0, backgroundColor: '' }}>
                                    <ResponsiveContainer width={'100%'} height={'100%'} minHeight={50}>
                                      <PieChart style={{ paddingTop: 0 }}>
                                        <Pie
                                          cx={'50%'}
                                          cy={9 + (windowHeight / 20 + windowWidth / 90)}
                                          outerRadius={8 + (windowHeight / 20 + windowWidth / 110)}
                                          innerRadius={7 + (windowHeight / 23 + windowWidth / 140)}
                                          startAngle={180}
                                          endAngle={0}
                                          paddingAngle={3}
                                          fill={'#8884d8'}
                                          dataKey='value'
                                          data={(typeof s.vehicleAvg !== 'undefined' && typeof s.vehicleAvg.current.avgWeekend !== 'undefined') ? s.vehicleAvg.current.avgWeekend : []}
                                          labelLine={false}
                                          maxRadius={201}
                                          label={this.renderCustomizedLabelVehicle}
                                        >
                                          {
                                            (typeof s.vehicleAvg !== 'undefined' && typeof s.vehicleAvg.current.avgWeekend !== 'undefined') ? s.vehicleAvg.current.avgWeekend.map((entry, index) => <Cell key={'gpw' + index} fill={VEHICLE_COLORS[index % VEHICLE_COLORS.length]} />) : []
                                          }
                                        </Pie>
                                      </PieChart>
                                    </ResponsiveContainer>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid key={Math.random()} item xs={(widthAdd + 0 + (windowWidth / (windowHeight / heightDiv)) ** ratioExp * windowWidth) / numPlots < col3age ? 4 : (windowWidth) / numPlots < col4age ? 4 : 3} container alignItems='center' style={{ height: '100%', width: '100%', paddingLeft: '0', backgroundColor: '' }}>
                                <Grid container direction='row' justifyContent='flex-start' alignItems='stretch' style={{ height: '100%', width: '100%', marginTop: 0, fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)', backgroundColor: '' }}>
                                  <Grid container direction='column' alignItems='center' justifyContent='center' style={{ height: '100%', width: '100%', paddingLeft: 0, backgroundColor: '' }}>
                                    <ResponsiveContainer width={'100%'} height={'100%'} minHeight={50}>
                                      <PieChart style={{ paddingTop: 0 }}>
                                        <Pie
                                          cx={'50%'}
                                          cy={9 + (windowHeight / 20 + windowWidth / 90)}
                                          outerRadius={8 + (windowHeight / 20 + windowWidth / 110)}
                                          innerRadius={7 + (windowHeight / 23 + windowWidth / 140)}
                                          startAngle={180}
                                          endAngle={0}
                                          paddingAngle={3}
                                          fill={'#8884d8'}
                                          dataKey='value'
                                          data={(typeof s.vehicleAvg !== 'undefined' && typeof s.vehicleAvg.current.avgWork !== 'undefined') ? s.vehicleAvg.current.avgWork : []}
                                          labelLine={false}
                                          maxRadius={202}
                                          label={this.renderCustomizedLabelVehicle}
                                        >
                                          {
                                            (typeof s.vehicleAvg !== 'undefined' && typeof s.vehicleAvg.current.avgWork !== 'undefined') ? s.vehicleAvg.current.avgWork.map((entry, index) => <Cell key={'gpwo' + index} fill={VEHICLE_COLORS[index % VEHICLE_COLORS.length]} />) : []
                                          }
                                        </Pie>
                                      </PieChart>
                                    </ResponsiveContainer>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={(windowWidth / numPlots) / numPlots < col4age ? false : 3} style={{ display: (windowWidth) / numPlots >= col4age ? '' : 'none', paddingLeft: '0', backgroundColor: '' }}>
                              {/* <Grid item xs={(windowWidth / numPlots) / numPlots < col4age ? false : 3} style={{ display: 'none', paddingLeft: '0', backgroundColor: '' }}> */}
                                <Grid container direction='row' justifyContent='flex-start' alignItems='stretch' style={{ height: '100%', paddingLeft: '0', marginTop: 0, fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)' }}>
                                  <Grid container direction='column' alignItems='stretch' justifyContent='center' style={{ height: '100%', width: '50%', backgroundColor: '' }}>
                                    <CountUp
                                      start={0}
                                      // end={typeof s.busiestBusDay !== 'undefined' ? s.busiestBusDay + 28 : 0}
                                      end={typeof s.busiestVehObj?.busiestBusDay !== 'undefined' ? s.busiestVehObj?.busiestBusDay + 28 : 0}
                                      duration={2.2}
                                      delay={0}
                                      separator=''
                                      decimals={0}
                                      decimal='.'
                                      prefix=''
                                      suffix=''
                                      formattingFn={(n) => {
                                        return ((numPlots >= 2) ? weekdayLookup[n].substr(0, 3) : weekdayLookup[n]);
                                      }}
                                    >
                                      {({ countUpRef }) => (
                                        <div>
                                          <span ref={countUpRef} style={{ color: colorsBuses[0] }} />
                                        </div>
                                      )}
                                    </CountUp>
                                    <Typography noWrap style={{ fontWeight: 400, fontSize: 'calc(' + (startLabelFontSize - 1).toString() + 'px + 0.25vw)', marginTop: 0 }}>
                                      Busiest Bus Day
                                    </Typography>
                                  </Grid>
                                  <Grid container direction='column' alignItems='stretch' justifyContent='center' style={{ display: mode === 'Conversion' || mode === 'Dwell' ? '' : '', fontWeight: 500, fontSize: 'calc(' + (startFontSize - 1).toString() + 'px + 0.5vw)', height: '100%', width: '50%', backgroundColor: '' }}>
                                    <CountUp
                                      start={0}
                                      // end={typeof s.busiestCarDay !== 'undefined' ? s.busiestCarDay + 28 : 0}
                                      end={typeof s.busiestVehObj?.busiestCarDay !== 'undefined' ? s.busiestVehObj?.busiestCarDay + 28 : 0}
                                      duration={2.2}
                                      delay={0}
                                      separator=''
                                      decimals={0}
                                      decimal='.'
                                      prefix=''
                                      suffix=''
                                      formattingFn={(n) => {
                                        return ((numPlots >= 2) ? weekdayLookup[n].substr(0, 3) : weekdayLookup[n]);
                                      }}
                                    >
                                      {({ countUpRef }) => (
                                        <div>
                                          <span ref={countUpRef} style={{ color: colorsCars[0] }} />
                                        </div>
                                      )}
                                    </CountUp>
                                    <Typography noWrap style={{ fontWeight: 400, fontSize: 'calc(' + (startLabelFontSize - 1).toString() + 'px + 0.25vw)', marginTop: 0 }}>
                                      Busiest Car Day
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </ImageListItem>
                  ]
                ))}
              </ImageList>
            }

          </Fragment>
        }
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(AveragesHeader));
