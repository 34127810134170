import axios from 'axios';
import moment from 'moment-timezone';

import config from './config';
import { clientData } from './clients';
import allLocationsArray from './locationsArray';


const _createLocalStorageArray = (dataObj) => {
  const returnArray = [];
  const numLocs = dataObj.reportLocations.length;

  for (let idx = 0; idx < numLocs; idx++) {
    returnArray.push({
      color: dataObj.chartColors[idx],
      locLabel: dataObj.locationLabels[idx],
      chartLabel: dataObj.chartLabels[idx],
      location: dataObj.reportLocations[idx],
      highlights: dataObj.highlights[idx] ?? '',
      map: dataObj.maps[idx] ?? {},
    });
  }

  return returnArray;
}

const _createTable = (locArray, reportClient) => {
  let counts = [];
  let startHr = clientData[reportClient].hrStart;
  let endHr = clientData[reportClient].hrEnd;

  if (reportClient === 'urbanumbrella') {
    if (locArray.toString().includes('MacDougal')) {
      startHr = 0;
      endHr = 24;
    }
    else if (locArray.toString().includes('Broadway') || locArray.toString().includes('Park')) {
      endHr = 23;
    }
  }

  // let [start, end, curHr, nextHr] = [0, 24, 0, 0];
  let [start, end, curHr, nextHr] = [startHr, endHr, 0, 0]; // start/end are variable per client/loc
  let [curHrString, hrString, nextHrStr] = ['', '', ''];

  for (let hr = 0; hr < end - start; hr++) {
    curHr = (hr + start);
    nextHr = curHr + 1;
  
    if (nextHr === 24) {
      nextHrStr = '12AM';
    } else if (nextHr >= 13) {
      nextHrStr = (nextHr - 12).toString() + 'PM';
    } else if (nextHr === 12) {
      nextHrStr = '12PM';
    } else {
      nextHrStr = nextHr.toString() + 'AM';
    }
  
    if (curHr >= 13) {
      curHrString = (curHr - 12).toString() + 'PM';
    } else if (curHr === 12) {
      curHrString = '12PM';
    } else if (curHr === 0) {
      curHrString = '12AM';
    } else {
      curHrString = curHr.toString() + 'AM';
    }
  
    hrString = curHrString + ' - ' + nextHrStr;
  
    let matchHr = curHr + 5;
    if (matchHr > 23) {
      matchHr = matchHr - 24;
    }
  
    counts[curHr - start] = { 'matchHr': matchHr, 'Time': hrString, 'Monday': 0, 'Tuesday': 0, 'Wednesday': 0, 'Thursday': 0, 'Friday': 0, 'Saturday': 0, 'Sunday': 0 };
  }

  return counts;
}

const _formatValue = (number, string) => {
  if (typeof number === 'undefined' || number === 0) return (string) ? '-' : 0;

  let newNum = (number >= 1000) ? number / 1000 : number;
  newNum = Math.round((newNum + Number.EPSILON) * 10) / 10; // round to 1 dec place

  if (Number.isNaN(newNum) || !Number.isFinite(newNum)) return (string) ? '-' : 0;

  return newNum;
}

const _fTime = (data, reportLocations, reportClient) => {
  const returnArray = [];

  // get start/end times for report locations
  const locsArray = reportLocations.flat(); // if any joined locs, flatten to single array
  const startArray = allLocationsArray.filter(loc => locsArray.includes(loc.qName) || locsArray.includes(loc.name)).map(l => moment(l.byTimeStart).tz('America/New_York'));
  const endArray = allLocationsArray.filter(loc => locsArray.includes(loc.qName) || locsArray.includes(loc.name)).map(l => moment(l.byTimeEnd).tz('America/New_York'));
  const start = (moment.min(startArray).isValid()) ? moment.min(startArray).tz('America/New_York') : moment('2019-01-01T05:00:00.000Z').tz('America/New_York');
  const end = (moment.max(endArray).isValid()) ? moment.max(endArray).tz('America/New_York') : moment('2019-01-02T05:00:00.000Z').tz('America/New_York');

  const getNewArray = (srcArray, idKey) => {
    let newArray = [];
  
    for (let i = 0, len = srcArray.length; i < len; i++) {
      newArray[srcArray[i]._id[idKey]] = srcArray[i];
    }
  
    return newArray;
  }
  const arrayIndex = getNewArray(data, 'time'); // returns array w/ times as keys

  while (start < end) {
    const timeStr = start.toISOString();
    const newEle = (arrayIndex[timeStr]) ? arrayIndex[timeStr] : { '_id': { 'time': timeStr }, 'men': 0, 'women': 0, 'children': 0, 'adults': 0, 'seniors': 0, 'count': 0, 'est': false };

    start.add(15, 'minutes');
    // start.add((reportClient === 'gcp' ? 60 : 15), 'minutes'); // gcp springboard data is in 1 hour intervals

    returnArray.push(newEle);
  }
  
  return returnArray
}

const _getBusiestHour = (dataArray) => {
  let byDayHourlyArray = JSON.parse(JSON.stringify(dataArray));
  byDayHourlyArray.sort((a, b) => {
    return (b.hrTotal - a.hrTotal);
  });

  const objHr = byDayHourlyArray[0]?.hour ?? undefined;
  const bHr = (objHr !== undefined) ? objHr - 5 : undefined;
  const suffix = (bHr !== undefined) ? ((bHr >= 12 || bHr < 0) ? 'PM' : 'AM') : ''; // set AM/PM before converting from 24hr
  const hour = (bHr !== undefined) ? ((bHr >= 13) ? (bHr - 12) : (bHr <= 0) ? (bHr + 12) : bHr) : '-'; // convert from 24hr to 12hr

  return [hour, suffix];
}

const _getPercDelta = (current, previous, string) => {
  if (typeof current === 'undefined' || typeof previous === 'undefined' || previous === 0) return (string) ? '-' : 0;

  let avg = ((current - previous) / previous) * 100;
  avg = Math.round((avg + Number.EPSILON) * 100) / 100; // round to 2 dec places

  if (Number.isNaN(avg) || !Number.isFinite(avg)) return (string) ? '-' : 0;

  return avg;
}

const _getSequentialTrends = (dataObj, numWksMos, format) => {
  let [trendsArray, changeArray] = [[], []];
  let y = 0;

  for (const [key, value] of Object.entries(dataObj)) {
    if (y < numWksMos) {
      const prevKey = moment(key).startOf(format.startOf).subtract(1, format.subtract); // monthly
      const prev = dataObj[prevKey.format(format.key)]; // monthly

      // console.log('key', key);
      // console.log('value:', value);
      // console.log('prev:', prev);

      if (y < numWksMos) {
        changeArray.push(
          {
            label: (!format.lbl) ? value?.daily?.label : moment(value?.daily?.label, format.lbl).format(format.newLbl), // week | month
            men: _getPercDelta(value?.daily?.avgs?.men, prev?.daily?.avgs?.men),
            women: _getPercDelta(value?.daily?.avgs?.women, prev?.daily?.avgs?.women),
            children: _getPercDelta(value?.daily?.avgs?.children, prev?.daily?.avgs?.men),
            adults: _getPercDelta(value?.daily?.avgs?.adults, prev?.daily?.avgs?.adults),
            seniors: _getPercDelta(value?.daily?.avgs?.seniors, prev?.daily?.avgs?.seniors),

            dailyAvg: _getPercDelta(value?.daily?.avgs?.count, prev?.daily?.avgs?.count),
            wkdayAvg: _getPercDelta(value?.weekday?.avgs?.count, prev?.weekday?.avgs?.count),
            wkendAvg: _getPercDelta(value?.weekend?.avgs?.count, prev?.weekend?.avgs?.count),
          }
        );
      }

      trendsArray.push(
        {
          label: value?.daily?.label,
          men: value?.daily?.avgs?.men || 0,
          women: value?.daily?.avgs?.women || 0,
          children: value?.daily?.avgs?.children || 0,
          adults: value?.daily?.avgs?.adults || 0,
          seniors: value?.daily?.avgs?.seniors || 0,
          dailyAvg: value?.daily?.avgs?.count || 0,
          wkdayAvg: value?.weekday?.avgs?.count || 0,
          wkendAvg: value?.weekend?.avgs?.count || 0,
          perChange: {
            men: _getPercDelta(value?.daily?.avgs?.men, prev?.daily?.avgs?.men),
            women: _getPercDelta(value?.daily?.avgs?.women, prev?.daily?.avgs?.women),
            children: _getPercDelta(value?.daily?.avgs?.children, prev?.daily?.avgs?.men),
            adults: _getPercDelta(value?.daily?.avgs?.adults, prev?.daily?.avgs?.adults),
            seniors: _getPercDelta(value?.daily?.avgs?.seniors, prev?.daily?.avgs?.seniors),

            dailyAvg: _getPercDelta(value?.daily?.avgs?.count, prev?.daily?.avgs?.count),
            wkdayAvg: _getPercDelta(value?.weekday?.avgs?.count, prev?.weekday?.avgs?.count),
            wkendAvg: _getPercDelta(value?.weekend?.avgs?.count, prev?.weekend?.avgs?.count),
          },
          genderPie: [
            { name: 'women', value: value?.daily?.avgs?.women || 0 },
            { name: 'men', value: value?.daily?.avgs?.men || 0 }
          ],
          agePie: [
            { name: 'children', value: value?.daily?.avgs?.children || 0 },
            { name: 'adults', value: value?.daily?.avgs?.adults || 0 },
            { name: 'seniors', value: value?.daily?.avgs?.seniors || 0 }
          ]
        }
      );

      y++;
    }
  }

  changeArray.reverse(); // order earliest -> latest
  trendsArray.reverse(); // order earliest -> latest

  return { changeArray, trendsArray };
}

// const _getComparisonDates = async (date) => {
//   try {
//     const startOfMoObj = moment(date).startOf('month');
//     const startOfPrevMoObj = moment(date).subtract(1, 'month').startOf('month');
//     const startOfMoPrevYearObj = moment(date).startOf('month').subtract(1, 'year');
//     const thisQuarterObj = moment(date);
//     const prevQuarterObj = moment(date).subtract(1, 'quarter');
//     const thisQuarterPrevYearObj = moment(date).subtract(4, 'quarter');

//     const datesObj = {
//       startOfMo: startOfMoObj.clone().format('YYYY-MM'), // 2022-04
//       startOfPrevMo: startOfPrevMoObj.clone().format('YYYY-MM'), // 2022-03
//       startOfMoPrevYear: startOfMoPrevYearObj.clone().format('YYYY-MM'), // 2021-04
//       thisQuarter: thisQuarterObj.clone().format('YYYY-[Q]Q'), // 2022-Q2
//       prevQuarter: prevQuarterObj.clone().format('YYYY-[Q]Q'), // 2022-Q1
//       thisQuarterPrevYear: thisQuarterPrevYearObj.clone().format('YYYY-[Q]Q') // 2021-Q2
//     };

//     const labelsObj = {
//       startOfMoLbl: startOfMoObj.clone().format('MMMM YYYY'), // April 2022
//       startOfPrevMoLbl: startOfPrevMoObj.clone().format('MMMM YYYY'), // March 2022
//       startOfMoPrevYearLbl: startOfMoPrevYearObj.clone().format('MMMM YYYY'), // April 2021
//       thisQuarterLbl: thisQuarterObj.clone().format('[Q]Q YYYY'), // Q2 2022
//       prevQuarterLbl: prevQuarterObj.clone().format('[Q]Q YYYY'), // Q1 2022
//       thisQuarterPrevYearLbl: thisQuarterPrevYearObj.clone().format('[Q]Q YYYY'), // Q2 2021
//       prevYTD: 'YTD ' + moment(date).subtract(1, 'year').format('YYYY') // YTD 2021
//     };

//     return { datesObj, labelsObj };
//   } catch (err) {
//     console.log('getComparisonDates - error: ');
//     console.log(err);
//   }
// }


const apiGetPdfReport = async (pdfData) => {
  try {
    // const { reportEstimate, reportStartDate, reportEndDate, reportByWeek, qLocation, labels, sDateData } = pdfData;
    const { adminClient, change, dateInterval, demo, detailed, highlights, labels, locType, mapRegions, qLocation, reportByWeek, reportEndDate, reportEstimate, reportStartDate, sDateData } = pdfData;
    const dataObj = {
      chartColors: getLocationColors(qLocation), // array of str w/ loc colors: ['#5899da', '#fbbf45', '#710162']
      chartLabels: objToArray(labels, false, true), // array of str w/ lbls for chart legend: ['Massachusetts Ave', 'Edgerly Rd (Total)', 'Edgerly Rd, Whole Foods Entering (Total)']
      locationLabels: objToArray(labels, false, false), // array of str w/ lbls for loc names: ['Massachusetts Ave', 'Edgerly Rd', 'Edgerly Rd + Whole Foods Entering']
      reportLocations: objToArray(qLocation, true, false), // array of arrays w/ loc query names: [['Massachusetts Ave'],['McDonald's Sidewalk', 'Whole Foods Sidewalk'],['McDonald's Sidewalk', 'Whole Foods Sidewalk', 'Whole Foods Entering']]
      highlights: [highlights], // array containing either empty string [''] or users edit text/html
    }
    const mapRegionsArray = dataObj.locationLabels.map(loc => (mapRegions[loc]) ? mapRegions[loc] : {}); // array of objects w/ loc map data or empty obj {}
    dataObj.maps = mapRegionsArray;

    const storageArray = _createLocalStorageArray(dataObj);

    const response = await axios.get(config.api.url + '/r1/traffic/rPdf2', {
      headers: {
        'Accept': 'application/pdf'
      },
      params: {
        // url: config.dashboard.url + '/report?puppeteer=true&date=' + reportStartDate + '&estimate=' + reportEstimate + '&reportByWeek=' + reportByWeek,
        // url: config.dashboard.url + '/report?puppeteer=true&sDate=' + reportStartDate + '&eDate=' + reportEndDate + '&estimate=' + reportEstimate + '&reportByWeek=' + reportByWeek + '&sDateData=' + sDateData,
        url: config.dashboard.url + '/report?puppeteer=true&sDate=' + reportStartDate + '&eDate=' + reportEndDate + '&estimate=' + reportEstimate + '&reportByWeek=' + reportByWeek + '&sDateData=' + sDateData + '&demo=' + demo + '&change=' + change + '&detailed=' + detailed + '&locType=' + locType + '&dateInterval=' + dateInterval,
        data: JSON.stringify(storageArray),
        ...(adminClient && { client: adminClient }), // only include if not '', allow admin to change client used to generate pdf report.
      },
      responseType: 'arraybuffer' // https://stackoverflow.com/questions/60454048/how-does-axios-handle-blob-vs-arraybuffer-as-responsetype
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const file = URL.createObjectURL(blob); // https://developer.mozilla.org/en-US/docs/Web/API/File_API/Using_files_from_web_applications#using_object_urls
    const link = document.createElement('a');
    link.href = file; // link.setAttribute('href', file);


    let save = true; // save: auto saves pdf when returned from api | open: auto opens pdf in new tab when returned from api
    if (save) {
      let fileDate = moment(reportStartDate).tz('America/New_York').format('YYYY-MM-DD');
      link.download = 'ExterosReport--' + fileDate + '.pdf'; // link.setAttribute('download', 'your-file-name.pdf');
      document.body.appendChild(link);
      link.click(); // save/download pdf
      link.remove(); // link.parentNode.removeChild(link);
      URL.revokeObjectURL(file);
    }
    else {
      window.open(link); // open pdf in new tab w/ pdf viewer
      // URL.revokeObjectURL(file); // if revoke is called here user can not save pdf
    }

    return;
  } catch (error) {
    // console.log('apiGetPdfReport error: ');
    // console.log(error);

    return;
  }
};

const formatAvgsData = async (dataObj, chartColors, chartLabels) => {
  const [returnObj, byDateArray] = [{}, []];

  for (const [i, d] of Object.entries(dataObj)) {
    let byDateObj = {
      data: d.byDayChart,
      chartColor: chartColors[i],
      chartLbl: chartLabels[i]
    }
    byDateArray.push(byDateObj);

    let dayOfWeekArray = JSON.parse(JSON.stringify(d.byDayOfWeekChart)); // create copy of byDayOfWeekChart then sort to get busiest day
    dayOfWeekArray.sort((a, b) => {
      return (b.count - a.count);
    });
    let busiestDay = (dayOfWeekArray[0]?.dayOfWeek && dayOfWeekArray[0]?.count !== 0) ? moment().isoWeekday(dayOfWeekArray[0].dayOfWeek).format('dddd') : '-';

    let totalTraffic = d.currentWeek?.daily?.totals?.count || 0;

    // Current - Daily, Weekend, Weekday Averages
    let thisWkDaily = d.currentWeek?.daily?.avgs?.count || 0;
    let thisWkWkend = d.currentWeek?.weekend?.avgs?.count || 0;
    let thisWkWkday = d.currentWeek?.weekday?.avgs?.count || 0;

    // Previous - Daily, Weekend, Weekday Averages
    let prevWkDaily = d.prevWeek?.daily?.avgs?.count || 0;
    let prevWkWkend = d.prevWeek?.weekend?.avgs?.count || 0;
    let prevWkWkday = d.prevWeek?.weekday?.avgs?.count || 0;

    // % Change - Daily, Weekend, Weekday Averages
    let dailyDelta = _getPercDelta(thisWkDaily, prevWkDaily, false);
    let wkEndDelta = _getPercDelta(thisWkWkend, prevWkWkend, false);
    let wkDayDelta = _getPercDelta(thisWkWkday, prevWkWkday, false);

    returnObj[i] = {
      label: chartLabels[i],
      avgs: {
        dailyAvg: thisWkDaily,
        wkendAvg: thisWkWkend,
        wkdayAvg: thisWkWkday,
      },
      avgsFormatted: {
        dailyAvg: _formatValue(thisWkDaily, false),
        wkendAvg: _formatValue(thisWkWkend, false),
        wkdayAvg: _formatValue(thisWkWkday, false),
      },
      perChange: {
        dailyAvg: dailyDelta,
        wkendAvg: wkEndDelta,
        wkdayAvg: wkDayDelta,
      },
      busiestDay: busiestDay,
      totalTraffic: totalTraffic,
      genderPie: [
        { name: 'women', value: d.currentWeek?.daily?.totals?.women || 0 }, { name: 'men', value: d.currentWeek?.daily?.totals?.men || 0 }
      ],
      agePie: [
        { name: 'children', value: d.currentWeek?.daily?.totals?.children || 0 }, { name: 'adults', value: d.currentWeek?.daily?.totals?.adults || 0 }, { name: 'seniors', value: d.currentWeek?.daily?.totals?.seniors || 0 }
      ],
      dayOfWeekChart: {
        data: d.byDayOfWeekChart, // data already sorted Mon - Sun for chart
        chartColor: chartColors[i],
        locationLabels: chartLabels[i]
      }
    }
  };

  returnObj.byDateChart = byDateArray;

  // console.log('#### formatAvgsData returnObj');
  // console.log(returnObj);

  return returnObj;
}

const formatAvgsData2 = async (dataObj, chartColors, chartLabels) => {
  const [returnObj, byDateArray] = [{}, []];

  for (const [i, d] of Object.entries(dataObj)) {
    let byDateObj = {
      data: d.byDayChart,
      chartColor: chartColors[i],
      chartLbl: chartLabels[i]
    }
    byDateArray.push(byDateObj);

    let dayOfWeekArray = JSON.parse(JSON.stringify(d.byDayOfWeekChart)); // create copy of byDayOfWeekChart then sort to get busiest day
    dayOfWeekArray.sort((a, b) => {
      return (b.count - a.count);
    });
    let busiestDay = (dayOfWeekArray[0]?.dayOfWeek && dayOfWeekArray[0]?.count !== 0) ? moment().isoWeekday(dayOfWeekArray[0].dayOfWeek).format('dddd') : '-';

    let totalTraffic = d.currentRange?.daily?.totals?.count || 0;

    // Current - Daily, Weekend, Weekday Averages
    let thisWkDaily = d.currentRange?.daily?.avgs?.count || 0;
    let thisWkWkend = d.currentRange?.weekend?.avgs?.count || 0;
    let thisWkWkday = d.currentRange?.weekday?.avgs?.count || 0;

    // Previous - Daily, Weekend, Weekday Averages
    let prevWkDaily = d.prevRange?.daily?.avgs?.count || 0;
    let prevWkWkend = d.prevRange?.weekend?.avgs?.count || 0;
    let prevWkWkday = d.prevRange?.weekday?.avgs?.count || 0;

    // % Change - Daily, Weekend, Weekday Averages
    let dailyDelta = _getPercDelta(thisWkDaily, prevWkDaily, false);
    let wkEndDelta = _getPercDelta(thisWkWkend, prevWkWkend, false);
    let wkDayDelta = _getPercDelta(thisWkWkday, prevWkWkday, false);

    returnObj[i] = {
      label: chartLabels[i],
      avgs: {
        dailyAvg: thisWkDaily,
        wkendAvg: thisWkWkend,
        wkdayAvg: thisWkWkday,
      },
      avgsFormatted: {
        dailyAvg: _formatValue(thisWkDaily, false),
        wkendAvg: _formatValue(thisWkWkend, false),
        wkdayAvg: _formatValue(thisWkWkday, false),
      },
      perChange: {
        dailyAvg: dailyDelta,
        wkendAvg: wkEndDelta,
        wkdayAvg: wkDayDelta,
      },
      busiestDay: busiestDay,
      totalTraffic: totalTraffic,
      genderPie: [
        { name: 'women', value: d.currentRange?.daily?.totals?.women || 0 }, { name: 'men', value: d.currentRange?.daily?.totals?.men || 0 }
      ],
      agePie: [
        { name: 'children', value: d.currentRange?.daily?.totals?.children || 0 }, { name: 'adults', value: d.currentRange?.daily?.totals?.adults || 0 }, { name: 'seniors', value: d.currentRange?.daily?.totals?.seniors || 0 }
      ],
      dayOfWeekChart: {
        data: d.byDayOfWeekChart, // data already sorted Mon - Sun for chart
        chartColor: chartColors[i],
        locationLabels: chartLabels[i]
      }
    }
  };

  returnObj.byDateChart = byDateArray;

  // console.log('#### formatAvgsData2 returnObj');
  // console.log(returnObj);

  return returnObj;
}

const sequentialTrendsData = async (data, byWeek, byMonth) => {
  let [moTrendsArray, moChangeArray, wkTrendsArray, wkChangeArray] = [[], [], [], []];
  let dateFormat = {
    week: {
      key: 'YYYY-MM-DD',
      startOf: 'isoWeek',
      subtract: 'week',
      lbl: '',
      newLbl: ''
    },
    month: {
      key: 'YYYY-MM',
      startOf: 'month',
      subtract: 'month',
      lbl: 'MMM-YYYY',
      newLbl: 'MMM'
    }
  }

  for (const [i, d] of Object.entries(data)) {
    if (byWeek.include) {
      let weekly = _getSequentialTrends(d.weekly, byWeek.number, dateFormat.week);
      wkChangeArray.push(weekly.changeArray);
      wkTrendsArray.push(weekly.trendsArray);
    }

    if (byMonth.include) {
      let monthly = _getSequentialTrends(d.monthly, byMonth.number, dateFormat.month);
      moChangeArray.push(monthly.changeArray);
      moTrendsArray.push(monthly.trendsArray);
    }
  }

  const returnObj = {
    weekly: { change: wkChangeArray, trends: wkTrendsArray },
    monthly: { change: moChangeArray, trends: moTrendsArray }
  }

  // console.log('#### sequentialTrendsData - returnObj: ', returnObj);

  return returnObj;
}

// const footTrafficData = async (dataObj, reportMonth) => {
//   // 4 location groups comparing 5 date ranges for each. (20 total)
//     // Month    - (This M vs Prev M) and (This M vs Last Year M) - (gcStartOfMon, gcStartOfPrevMon, gcStartOfMonPrevYear)
//     // Quarter  - (This Q vs Prev Q) and (This Q vs Last Year Q) - (gcThisQuarter, gcPrevQuarter, gcThisQuarterPrevYear)
//     // YTD      - (This YTD vs Last YTD) - (get directly from data: currentYTD, prevYTD)

//   const { datesObj, labelsObj } = await _getComparisonDates(reportMonth);

//   const returnObj = {};
//   returnObj.labels = labelsObj;

//   for (const [i, d] of Object.entries(dataObj)) {
//     // Month
//     let thisMo = d.monthly[datesObj.startOfMo]?.daily?.avgs?.count;
//     let prevMo = d.monthly[datesObj.startOfPrevMo]?.daily?.avgs?.count;
//     let thisMoPrevYr = d.monthly[datesObj.startOfMoPrevYear]?.daily?.avgs?.count;
//     let moDelta = _getPercDelta(thisMo, prevMo, true);
//     let moDeltaPrevYr = _getPercDelta(thisMo, thisMoPrevYr, true);

//     // Quarter
//     // let thisQtr = d.quarterly[datesObj.thisQuarter]?.daily?.avgs?.count;
//     // let prevQtr = d.quarterly[datesObj.prevQuarter]?.daily?.avgs?.count;
//     // let thisQtrPrevYr = d.quarterly[datesObj.thisQuarterPrevYear]?.daily?.avgs?.count;
//     // let qtrDelta = _getPercDelta(thisQtr, prevQtr, true);
//     // let qtrDeltaPrevYr = _getPercDelta(thisQtr, thisQtrPrevYr, true);

//     // YTD
//     let thisYTD = d.currentYTD.daily?.avgs?.count;
//     let prevYTD = d.prevYTD.daily?.avgs?.count;
//     let ytdDelta = _getPercDelta(thisYTD, prevYTD, true);

//     returnObj[i] = {
//       moDelta: moDelta,
//       moDeltaPrevYr: moDeltaPrevYr,
//       // qtrDelta: qtrDelta,
//       // qtrDeltaPrevYr: qtrDeltaPrevYr,
//       ytdDelta: ytdDelta,
//     }
//   }

//   // console.log('#### Foot Traffic returnObj: ', returnObj);

//   return returnObj;
// }

const formatTimeData = async (dataObj, chartColors, chartLabels, reportClient, reportLocations) => {
  const [returnObj, byTimeArray] = [{}, []];

  for (const [i, d] of Object.entries(dataObj)) {

    let byTimeObj = {
      // data: d.all,
      data: _fTime(d.all, reportLocations, reportClient),
      chartColor: chartColors[i],
      locationLabels: chartLabels[i],
      totalCount: d.totals?.count ?? 0
    }
    byTimeArray.push(byTimeObj);

    let byDayHourlyArray = JSON.parse(JSON.stringify(d.bdh)); // create copy of byDayHourly then sort to get maxCount
    byDayHourlyArray.sort((a, b) => {
      return (b.max - a.max);
    });
    let maxCount = (byDayHourlyArray[0]?.max) ? byDayHourlyArray[0].max : 0;

    const [hour, suffix] = _getBusiestHour(d.bdh);

    // let tableArray = _createTable(); // returns array /w obj for each hour, { matchHr: 5, Time: '12AM - 1AM', Monday: 0, Tuesday: 0, ... }
    let tableArray = _createTable(reportLocations[i], reportClient); // returns array /w obj for each hour, { matchHr: 5, Time: '12AM - 1AM', Monday: 0, Tuesday: 0, ... }
    tableArray.forEach((row, i) => {
      let key = row.matchHr;
      let hour = d.bdh.filter(row2 => key === row2.hour);

      if (hour.length > 0) {
        hour[0].Time = row.Time;
        tableArray[i] = hour[0];
      }
      else {
        tableArray[i].max = 0;
        tableArray[i].hour = key;
      }
    });

    returnObj[i] = {
      label: chartLabels[i],
      busiestHr: {
        hr: hour,
        suffix: suffix
      },
      byDayHourly: {
        data: tableArray,
        maxCount: maxCount,
        locationLabels: chartLabels[i]
      },
      byTimeSplit: {
        data: {
          weekday: d.weekday,
          weekend: d.weekend,
        },
        totalCounts: d.totals,
        locationLabels: chartLabels[i]
      }
    }
  };

  returnObj.byTimeChart = byTimeArray;

  // console.log('#### formatTimeData returnObj');
  // console.log(returnObj);

  return returnObj;
}

// const formatReportDates = (date, byWeek = true) => {
//   try {
//     // byWeek.format()
//     const start = (byWeek) ? date.clone().startOf('isoWeek') : date.clone().startOf('month');
//     const end = (byWeek) ? date.clone().endOf('isoWeek') : date.clone().endOf('month');
//     const upperLbl = (byWeek) ? 'Week' : 'Month';

//     const returnObj = {
//       abr: (byWeek) ? 'wk' : 'mo',
//       lower: (byWeek) ? 'week' : 'month',
//       upper: upperLbl,
//       highlights: start.clone().format('MMMM Do, YYYY'), // August 22nd, 2022
//       mainHeader: upperLbl + ' of ' + start.clone().format('MMMM D, YYYY') + ' ( ' + start.clone().format('M/D/YY') + ' - ' + end.clone().format('M/D/YY') + ' )', // Week|Month of August 22, 2022 ( 8/22/22 - 8/28/22 )
//     }

//     return returnObj;
//   } catch (err) {
//     console.log('formatReportDates - error: ');
//     console.log(err);

//     return {
//       abr: (byWeek) ? 'wk' : 'mo',
//       lower: (byWeek) ? 'week' : 'month',
//       upper: (byWeek) ? 'Week' : 'Month',
//       highlights: '',
//       mainHeader: ''
//     }
//   }
// }

const formatReportDates = (sDate, eDate, reportByWeek) => {
  try {
    let returnObj = {};

    if (reportByWeek < 3) {
      const range = { 1: 'isoWeek', 2: 'month', 3: 'day' };
      const start = sDate.clone().startOf(range[reportByWeek]);
      const end = sDate.clone().endOf(range[reportByWeek]);
      const upperLbl = (reportByWeek === 1) ? 'Week' : 'Month';
  
      returnObj = {
        abr: (reportByWeek === 1) ? 'wk' : 'mon',
        lower: (reportByWeek === 1) ? 'week' : 'month',
        upper: upperLbl,
        highlights: start.clone().format((reportByWeek === 1) ? 'MMMM Do, YYYY' : 'MMMM YYYY'), // August 22nd, 2022 | August 2022
        mainHeader: upperLbl + ' of ' + start.clone().format((reportByWeek === 1) ? 'MMMM D, YYYY' : 'MMMM YYYY') + ' ( ' + start.clone().format('M/D/YY') + ' - ' + end.clone().format('M/D/YY') + ' )', // Week|Month of August 22, 2022 ( 8/22/22 - 8/28/22 )
      }
    }
    else {
      const start = sDate.clone().startOf('day');
      const end = eDate.clone().endOf('day');
  
      returnObj = {
        abr: '',
        lower: '',
        upper: '',
        highlights: 'From ' + start.clone().format('M/D/YY') + ' to ' + end.clone().format('M/D/YY'), // From 8/22/22 to 8/28/22
        mainHeader: start.clone().format('MMMM D, YYYY') + ' ( ' + start.clone().format('M/D/YY') + ' - ' + end.clone().format('M/D/YY') + ' )', // August 22, 2022 ( 8/22/22 - 8/28/22 )
      }
    }

    return returnObj;
  } catch (err) {
    // console.log('formatReportDates - error: ');
    // console.log(err);

    return {
      abr: '',
      lower: '',
      upper: '',
      highlights: '',
      mainHeader: ''
    }
  }
}

const getImage = (imgPath, square, logo = false) => {
  try {
    // imgPath is partly formed w/ locLabel which may include a list of filtered v-types surrounded by [].
    return require('../components/layout/' + imgPath.replace(/_\[.*\]/, '')); // remove v-types if included
  }
  catch (e) {
    if (square) return require('../components/layout/blank_report_square.png');
    if (logo) return require('../components/layout/blank_logo.png');

    return require('../components/layout/blank_report.png');
  }
}

const getLocalStorageData = () => {
  let [dataArray, colorsArray, locLabelsArray, chartLabelsArray, locsArray, highlightsArray, mapsArray] = [[], [], [], [], [], [], []];

  try {
    dataArray = JSON.parse(window.localStorage.getItem('reportData') || '[]');
  } catch (e) { console.log(e); }

  if (dataArray.length) {  
    dataArray.forEach(loc => {
      colorsArray.push(loc.color);
      locLabelsArray.push(loc.locLabel);
      chartLabelsArray.push(loc.chartLabel);
      locsArray.push(loc.location);
      if (loc.highlights) highlightsArray.push(loc.highlights); // return empty array if highlights are empty string
      if (loc.map) mapsArray.push(loc.map)
    });
  }

  return [colorsArray, locLabelsArray, chartLabelsArray, locsArray, highlightsArray, mapsArray];
};

const getLocationColors = (locationsObj) => {
  const comboColors = [
    '#fbbf45', '#710162', '#01545a', '#26294a', '#a12a5e', '#017351', '#ef6a32', '#ed0345', '#1a1334', '#03c383', '#aad962', '#110141',
    '#26294a', '#fbbf45', '#01545a', '#a12a5e', '#017351', '#ef6a32', '#710162', '#ed0345', '#1a1334', '#03c383', '#aad962', '#110141'
    // '#fbbf45', '#a12a5e', '#110141', '#01545a', '#26294a', '#017351', '#ef6a32', '#710162', '#ed0345', '#1a1334', '#03c383', '#aad962',
    // '#fbbf45', '#a12a5e', '#110141', '#01545a', '#26294a', '#017351', '#ef6a32', '#710162', '#ed0345', '#1a1334', '#03c383', '#aad962',
  ];

  let [returnArray, count] = [[], 0];

  for (let [key, value] of Object.entries(locationsObj)) {
    if (value.length) {
      let color = '';

      if (value.length > 1) {
        color = comboColors[count];
        count++;
      }
      else {
        color = allLocationsArray.filter(loc => value.includes(loc.qName) || value.includes(loc.name)).map(l => l.color)[0];
      }

      returnArray.push(color);
    }
  }

  return returnArray;
};

const getQueryDates = async (sDate, eDate, reportByWeek, dataStartDate) => {
  try {
    let returnObj = {};

    if (reportByWeek < 3) {
      const period = (reportByWeek === 1) ? 'isoWeek' : 'month';
      const duration = (reportByWeek === 1) ? 'week' : 'month';

      const startOf = moment(sDate).clone().startOf(period);
      const endOf = moment(sDate).clone().endOf(period);
      const endOfPrev = moment(sDate).clone().subtract(1, duration).endOf(period);
      let startOfPrev = moment(sDate).clone().subtract(1, duration).startOf(period);
      startOfPrev = (startOfPrev.isBefore(dataStartDate)) ? dataStartDate : startOfPrev; // if before start of data, set to data start date

      returnObj = {
        currentStart: startOf,
        currentEnd: endOf,
        prevStart: startOfPrev,
        prevEnd: endOfPrev
      }
    }
    else {
      const startOf = moment(sDate).clone().startOf('day');
      const endOf = moment(eDate).clone().endOf('day');
      const endOfPrev = startOf.clone().subtract(1, 'day').endOf('day');
      let startOfPrev = startOf.clone().subtract(endOf.diff(startOf, 'days') + 1, 'days').startOf('day');
      startOfPrev = (startOfPrev.isBefore(dataStartDate)) ? dataStartDate : startOfPrev; // if before start of data, set to data start date

      returnObj = {
        currentStart: startOf,
        currentEnd: endOf,
        prevStart: startOfPrev,
        prevEnd: endOfPrev
      }
    }

    return returnObj;
  } catch (err) {
    // console.log('getQueryDates - error: ');
    // console.log(err);
  }
}

const getQueryDates2 = async (sDate, eDate, reportByWeek) => {
  try {
    let returnObj = {};

    if (reportByWeek < 3) {
      const period = (reportByWeek === 1) ? 'isoWeek' : 'month';
      const duration = (reportByWeek === 1) ? 'week' : 'month';

      const startOf = moment(sDate).clone().startOf(period);
      const endOf = moment(sDate).clone().endOf(period);
      const startOfPrev = moment(sDate).clone().subtract(1, duration).startOf(period);
      const endOfPrev = moment(sDate).clone().subtract(1, duration).endOf(period);


      const startOfYear = moment(sDate).clone().startOf('year');
      const startOfPrevYear = moment(sDate).clone().subtract(1, 'year').startOf('year');
      const endOfMoPrevYear = moment(sDate).clone().subtract(1, 'year').endOf('month');


      returnObj = {
        currentStart: startOf,
        currentEnd: endOf,
        prevStart: startOfPrev,
        prevEnd: endOfPrev,

        startOfYear: startOfYear,
        startOfPrevYear: startOfPrevYear,
        endOfMoPrevYear: endOfMoPrevYear,
      }
    }
    else {
      const startOf = moment(sDate).clone().startOf('day');
      const endOf = moment(eDate).clone().endOf('day');
      const startOfPrev = startOf.clone().subtract(endOf.diff(startOf, 'days') + 1, 'days').startOf('day');
      // startOfPrev = (startOfPrev.isBefore(this.state.dateRange.start)) ? this.state.dateRange.start : startOfPrev; // if before start of data, set to data start date
      const endOfPrev = startOf.clone().subtract(1, 'day').endOf('day');


      const startOfYear = moment(sDate).clone().startOf('year');
      const startOfPrevYear = moment(sDate).clone().subtract(1, 'year').startOf('year');
      const endOfMoPrevYear = moment(sDate).clone().subtract(1, 'year').endOf('month');
      

      returnObj = {
        currentStart: startOf,
        currentEnd: endOf,
        prevStart: startOfPrev,
        prevEnd: endOfPrev,

        startOfYear: startOfYear,
        startOfPrevYear: startOfPrevYear,
        endOfMoPrevYear: endOfMoPrevYear,
      }
    }

    return returnObj;
  } catch (err) {
    // console.log('getQueryDates2 - error: ');
    // console.log(err);
  }
}

const objToArray = (dataObj, arrayOfArrays, chartLbls = false) => {
  const returnArray = [];

  for (let [key, value] of Object.entries(dataObj)) {
    if (value.length) {
      if (arrayOfArrays) {
        returnArray.push(value);
        continue;
      }

      if (chartLbls) {
        returnArray.push(value[0]);
        continue;
      }

      // returnArray.push(value[0].replace(/(?:^\s*)|(?:\s(?:\(Total\)|(?:\(Avg\)))\s*$)/g, '').replace(/(?:\,)/g, ' +')); // trim leading/trailing white space, remove (Avg)|(Total) label, and replace commas w/ '+'
      returnArray.push(value[0].replace(/(?:^\s*)|(?:\s(?:\(Total\)|(?:\(Avg\)))\s*$)/g, '')); // trim leading/trailing white space and remove (Avg)|(Total) label
    }
  }

  return returnArray;
};

const getShowDemographicsArray = (client, showDemo, reportLocations) => {
  if (!showDemo) return new Array(reportLocations.length).fill(false); // if user doesn't want demographics, return array with all false

  const returnArray = [];
  const noDemoLocsArray = allLocationsArray.filter(loc => client === loc.client.toLowerCase() && loc.hideDemographics).map(l => l.qName || l.name); // all client locs without demographics

  reportLocations.forEach(el => {
    let demoCheck = true;
    el.forEach(loc => { if (noDemoLocsArray.includes(loc)) demoCheck = false; });

    // if (!showDemo) demoCheck = false;
    returnArray.push(demoCheck);
  });

  return returnArray;
}

export {
  apiGetPdfReport,
  formatAvgsData, formatTimeData, formatReportDates,
  getShowDemographicsArray, getImage, getLocalStorageData, getLocationColors, getQueryDates,
  objToArray,
  formatAvgsData2, getQueryDates2, sequentialTrendsData,
  // footTrafficData
};
