import moment from 'moment-timezone';


// enter new client in alphabetical order with client name in lowercase.
export const clientData = {
  'all': {
    logo: '', // dashboard client logo, empty '' displays a transparent placeholder (NavBar.js)
    // logoSwap: {
    //   'abrse.com': 'absre.png', // if user is displayed a diff logo from client, include this obj w/ the user's email domain as key and logo path as value (see 'usp')
    // },
    reports: {
      newReports: true, // enable/disable new reports
      logo: 'exteros_blue.png', // client logo displayed on new reports
      reportTitle: 'Exteros',
      reportSubTitle: '',
    },
    companyName: '', // company name used when adding access control users (NavBar.js - getCompanyName())
    retailDash: false, // enable/disable dashboard mode toggle (traffic, dwell, conversion - Dashboard)
    sameWeather: false, // if all locations use same weather data (Dashboard.js isSameWeather())
    calStart: moment().tz('America/New_York').subtract(30, 'days').startOf('day'), // controls dashboard calendar default start/end dates (Dashboard.js getClientDate())
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T05:00:00.000Z', // controls byTimeofDay start/end time (Dashboard.js formatArray())
    byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
    hrStart: 0, // controls TimeByDayOfWeek start/end hour (urbanumbrella changes by location - Dashboard.js getTableRows())
    hrEnd: 24,
    trimDates: true, // enable/disable adjusting cal starting/ending dates to be within bounds of that locations start/end date in locationArray (Dashboard.js boolean passed to PresetDateRangePicker)
    userRoles: true, // roles used for video streaming access
    clientListHide: false, // Admin: use to exclude client from admin dropdown
    clientListName: 'all', // Admin: used specifically w/ Lindt and DowntownAlliance, client name in token not all lowercase
    categories: {}, // leave admin categories empty - example structure below
    // categories: {
    //   'Retail Type': { // used as category section title on loc cards and button name in Category Compare
    //     aggregation: 'average', // aggregation type used with Category Compare
    //     types: {
    //       'Downtown': 'total', // category name and aggregation type used on location card and MultiCompare
    //       'Outlet Centers': 'total',
    //       'Shopping Malls': 'total',
    //     },
    //   }
    // },
  },
  'absre': {
    // usp locations
    logo: 'abs/absLogo_white.svg',
    reports: {
      newReports: true,
      logo: 'abs/absLogo.svg',
      reportTitle: '',
      reportSubTitle: '',
    },
    companyName: '',
    retailDash: false,
    sameWeather: true,
    calStart: moment('2022-10-01').tz('America/New_York').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
    byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
    hrStart: 0,
    hrEnd: 24,
    trimDates: true,
    clientListHide: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'abs': {
    logo: 'abs/absLogo_white.svg',
    reports: {
      newReports: true,
      logo: 'abs/absLogo.svg',
      reportTitle: '',
      reportSubTitle: '',
    },
    companyName: '',
    retailDash: false,
    sameWeather: true,
    calStart: moment().tz('America/New_York').subtract(60, 'days').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
    byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
    hrStart: 0,
    hrEnd: 24,
    trimDates: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'bpc': {
    logo: 'bpc/bpcLogo_header.svg',
    reports: {
      newReports: true,
      logo: 'bpc/bpcLogo.svg',
      reportTitle: 'Bryant Park Corporation',
      reportSubTitle: '',
    },
    companyName: '',
    retailDash: false,
    sameWeather: true,
    calStart: moment().tz('America/New_York').subtract(29, 'days').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
    byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
    hrStart: 0,
    hrEnd: 24,
    trimDates: true,
    categories: {
      'Traffic Type': {
        aggregation: 'average',
        types: {
          'Entering': 'total',
          'Exiting': 'total',
          'Passing': 'total',
        },
      },
      'Entrances': {
        aggregation: 'average',
        types: {
          '6th Ave Enter': 'total',
          '42nd St Enter': 'total',
          '40th St Enter': 'total',
          'Heiskell Enter': 'total'
        },
      },
      'Exits': {
        aggregation: 'average',
        types: {
          '6th Ave Exit': 'total',
          '42nd St Exit': 'total',
          '40th St Exit': 'total',
          'Heiskell Exit': 'total'
        },
      },
    },
  },
  'brickclik': {
    logo: 'brickclik.png',
    reports: {
      newReports: true,
      logo: 'brickclik/brickclikLogo.png',
      reportTitle: 'Brik + Clik',
      reportSubTitle: '',
    },
    companyName: '',
    retailDash: true,
    sameWeather: true,
    calStart: moment().tz('America/New_York').subtract(30, 'days').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T15:00:00.000Z', // 10am
    byTimeEnd: '2019-01-02T00:00:00.000Z', // 7pm
    hrStart: 10, // 10am
    hrEnd: 19, // 7pm
    trimDates: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Shopping Malls': 'total',
        },
      }
    },
  },
  'cw': {
    logo: 'cw/cw_header.svg',
    reports: {
      newReports: true,
      logo: 'cw/cw.svg',
      reportTitle: 'Cushman & Wakefield',
      reportSubTitle: '',
    },
    companyName: '',
    retailDash: false,
    sameWeather: true,
    calStart: moment('2023-10-26').tz('America/New_York').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
    byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
    hrStart: 9, // 9am
    hrEnd: 22, // 10pm
    trimDates: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'delshah': {
    logo: 'delshah/delshahLogo_white.svg',
    reports: {
      newReports: true,
      logo: 'delshah/delshahLogo.svg',
      reportTitle: 'Delshah',
      reportSubTitle: '',
    },
    companyName: '',
    retailDash: false,
    sameWeather: true,
    calStart: moment().tz('America/New_York').subtract(30, 'days').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
    byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
    hrStart: 7, // 7am
    hrEnd: 24,
    trimDates: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'dgd': {
    logo: 'dgd/dgdLogo_header.svg',
    reports: {
      newReports: true,
      logo: 'dgd/dgdLogo.svg',
      reportTitle: '',
      reportSubTitle: '',
    },
    companyName: '',
    retailDash: false,
    sameWeather: true,
    calStart: moment().tz('America/New_York').subtract(14, 'days').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
    byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
    hrStart: 7, // 7am
    hrEnd: 22, // 10pm
    trimDates: true,
    vehicleLocations: true,
    onlyVehicles: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
          'Shopping Malls': 'total',
        },
      },
    },
  },
  'downtownalliance': {
    logo: 'DowntownAlliance/daLogo_white.svg',
    reports: {
      newReports: true,
      logo: 'DowntownAlliance/daLogo.svg',
      reportTitle: 'Downtown Alliance',
      reportSubTitle: '',
    },
    companyName: 'The Alliance for Downtown New York',
    retailDash: false,
    sameWeather: true,
    calStart: moment().tz('America/New_York').subtract(30, 'days').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
    byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
    hrStart: 7, // 7am
    hrEnd: 22, // 10pm
    trimDates: true,
    clientListName: 'DowntownAlliance',
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'faa': {
    logo: 'faa/faa.svg',
    reports: {
      newReports: true,
      logo: 'faa/faaLogo.svg',
      reportTitle: 'Fifth Ave Association',
      reportSubTitle: '',
    },
    companyName: '',
    retailDash: false,
    sameWeather: true,
    calStart: moment().tz('America/New_York').subtract(21, 'days').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
    byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
    hrStart: 7, // 7am
    hrEnd: 22, // 10pm
    trimDates: true,
    vehicleLocations: true,
    userRoles: true,
    categories: {
      'Street Side': {
        aggregation: 'average',
        types: {
          'West Side': 'total',
          'East Side': 'total',
        },
      },
    },
  },
  'flatiron': {
    logo: 'flatiron/flatironLogo_white.svg',
    reports: {
      newReports: true,
      logo: 'flatiron/flatironLogo.svg',
      reportTitle: 'Flatiron NoMad Partnership',
      reportSubTitle: '',
    },
    companyName: '',
    retailDash: false,
    sameWeather: true,
    calStart: moment().tz('America/New_York').subtract(14, 'days').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
    byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
    hrStart: 0,
    hrEnd: 24,
    trimDates: true,
    vehicleLocations: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'frontrunner': {
    logo: 'frontrunner.svg',
    reports: {
      newReports: true,
      logo: 'frontrunner/frontrunnerLogo.png',
      reportTitle: 'Frontrunner',
      reportSubTitle: '',
    },
    companyName: '',
    retailDash: false,
    sameWeather: false, // 1 location
    calStart: moment('2021-11-24').tz('America/New_York').subtract(30, 'days').startOf('day'),
    calEnd: moment('2021-11-24').tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
    byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
    hrStart: 7, // 7am
    hrEnd: 24, // midnight
    trimDates: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'gcp': {
    logo: 'gcp.svg',
    reports: {
      newReports: true,
      logo: 'gcpLogo.svg',
      reportTitle: '',
      reportSubTitle: '',
    },
    companyName: 'Grand Central Partnership',
    retailDash: false,
    sameWeather: true,
    calStart: moment('2025-02-01').tz('America/New_York').subtract(27, 'days').startOf('day'),
    calEnd: moment('2025-02-28').tz('America/New_York').endOf('day'),
    byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
    byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
    hrStart: 0,
    hrEnd: 24,
    trimDates: true,
    vehicleLocations: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'goodwill': {
    logo: 'goodwill/goodwillLogo_header.svg',
    reports: {
      newReports: true,
      logo: 'goodwill/goodwillLogo.svg',
      reportTitle: 'Goodwill',
      reportSubTitle: '',
    },
    companyName: '',
    retailDash: false,
    sameWeather: true,
    calStart: moment().tz('America/New_York').subtract(14, 'days').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
    byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
    hrStart: 7, // 7am
    hrEnd: 22, // 10pm
    trimDates: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'hsbid': {
    logo: 'hsbid/hsbidLogo.svg',
    reports: {
      newReports: true,
      logo: 'hsbid/hsbidReportLogo.svg',
      reportTitle: 'Spring Street Park',
      reportSubTitle: 'Hudson Square Business Improvement District',
    },
    companyName: '',
    retailDash: true,
    sameWeather: true,
    calStart: moment().tz('America/New_York').subtract(30, 'days').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
		byTimeStart: '2019-01-01T05:00:00.000Z', // 12am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // 12am
		hrStart: 0,
		hrEnd: 24,
    trimDates: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'jemb': {
    logo: 'jemb/jembLogo_white.svg',
    reports: {
      newReports: true,
      logo: 'jemb/jembLogo.svg',
      reportTitle: 'JEMB Realty',
      reportSubTitle: '',
    },
    companyName: '',
    retailDash: false,
    sameWeather: true,
    calStart: moment().tz('America/New_York').subtract(120, 'days').startOf('day'), //moment('2023-07-25').tz('America/New_York').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(6, 'day').endOf('day'),
    byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
    byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
    hrStart: 0,
    hrEnd: 24,
    trimDates: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'jll': {
    logo: 'jll/jllLogo_white.svg',
    reports: {
      newReports: true,
      logo: 'jll/jllLogo.svg',
      reportTitle: 'JLL',
      reportSubTitle: '',
    },
    companyName: '',
    retailDash: false,
    sameWeather: true,
    calStart: moment('2023-08-05').tz('America/New_York').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
    byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
    hrStart: 9, // 9am
    hrEnd: 22, // 10pm
    trimDates: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'ksr': {
    logo: 'ksr.png',
    reports: {
      newReports: true,
      logo: 'ksr/ksrLogo.png',
      reportTitle: 'KSR',
      reportSubTitle: '',
    },
    companyName: 'Kassin Sabbagh Realty',
    retailDash: false,
    sameWeather: true,
    calStart: moment('2021-08-13').tz('America/New_York').subtract(14, 'days').startOf('day'),
    calEnd: moment('2021-08-13').tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
    byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
    hrStart: 9, // 9am
    hrEnd: 22, // 10pm
    trimDates: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'lee': {
    logo: 'lee/leeLogo_white.svg',
    reports: {
      newReports: true,
      logo: 'lee/leeLogo.svg',
      reportTitle: '157 E Houston St',
      reportSubTitle: 'Lower East Side Pizza Shops',
    },
    companyName: '',
    retailDash: false,
    sameWeather: true,
    calStart: moment('2022-10-06').tz('America/New_York').startOf('day'),
    calEnd: moment('2022-10-08').tz('America/New_York').endOf('day'),
    byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
    byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
    hrStart: 0,
    hrEnd: 24,
    trimDates: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'lindt': {
    logo: 'Lindt/lindtLogo_white.svg',
    reports: {
      newReports: true,
      logo: 'Lindt/lindtLogo.svg',
      reportTitle: 'Lindt',
      reportSubTitle: '',
    },
    companyName: 'Lindt & Sprüngli (USA)',
    retailDash: false,
    sameWeather: false,
    calStart: moment().tz('America/New_York').subtract(30, 'days').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
    byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
    hrStart: 0,
    hrEnd: 24,
    trimDates: true,
    clientListName: 'Lindt',
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
          'Outlet Centers': 'total',
          'Shopping Malls': 'total',
        },
      },
    },
  },
  'lsbid': {
    logo: 'lsbid/lsbidLogo_white.svg',
    reports: {
      newReports: true,
      logo: 'lsbid/lsbidLogo.svg',
      reportTitle: 'Lincoln Square BID',
      reportSubTitle: '',
    },
    companyName: '',
    retailDash: false,
    sameWeather: true,
    calStart: moment().tz('America/New_York').subtract(14, 'days').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
    byTimeEnd: '2019-01-02T00:00:00.000Z', // 7pm
    hrStart: 7, // 7am
    hrEnd: 19, // 7pm
    trimDates: true,
    vehicleLocations: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'mcdonalds': {
    logo: 'mcdonalds.png',
    reports: {
      newReports: true,
      logo: 'mcdonalds/mcdonaldsLogo.png',
      reportTitle: '245 Massachusetts Ave',
      reportSubTitle: 'Mass Ave vs Edgerly Rd',
    },
    companyName: '',
    retailDash: false,
    sameWeather: true,
    calStart: moment('2022-11-15').tz('America/New_York').subtract(30, 'days').startOf('day'),
    calEnd: moment('2022-11-15').tz('America/New_York').endOf('day'),
    byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
    byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
    hrStart: 0,
    hrEnd: 24,
    trimDates: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'naya': {
    logo: 'naya.svg',
    reports: {
      newReports: true,
      logo: 'nayaLogo.svg',
      reportTitle: '',
      reportSubTitle: '',
    },
    companyName: '',
    retailDash: false,
    sameWeather: true,
    calStart: moment().tz('America/New_York').subtract(30, 'days').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
    byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
    hrStart: 7, // 7am
    hrEnd: 22, // 10pm
    trimDates: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'showfields': {
    logo: 'showfields.png',
    reports: {
      newReports: true,
      logo: 'showfields/showfieldsLogo.png',
      reportTitle: 'Showfields',
      reportSubTitle: '',
    },
    companyName: '',
    retailDash: true,
    sameWeather: true,
    calStart: moment().tz('America/New_York').subtract(30, 'days').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T15:00:00.000Z', // 10am
    byTimeEnd: '2019-01-02T02:00:00.000Z', // 9pm
    hrStart: 10, // 10am
    hrEnd: 21, // 9pm
    trimDates: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Shopping Malls': 'total',
        },
      }
    },
  },
  'tavistock': {
    logo: 'tavistock.png',
    reports: {
      newReports: true,
      logo: 'tavistock.png',
      reportTitle: 'Tavistock',
      reportSubTitle: '',
    },
    companyName: 'Tavistock',
    retailDash: false,
    sameWeather: true,
    calStart: moment('02-01-2020', 'MM-DD-YYYY').tz('America/New_York').startOf('day'),
    calEnd: moment('02-22-2020', 'MM-DD-YYYY').tz('America/New_York').startOf('day'),
    byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
    byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
    hrStart: 9, // 9am
    hrEnd: 22, // 10pm
    trimDates: true,
    clientListHide: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'urbanumbrella': {
    logo: 'urbanumbrella.png',
    reports: {
      newReports: true,
      logo: 'urbanumbrella/urbanumbrellaLogo.png',
      reportTitle: 'Urban Umbrella',
      reportSubTitle: '',
    },
    companyName: 'Urban Umbrella',
    retailDash: false,
    sameWeather: true,
    calStart: moment().tz('America/New_York').subtract(60, 'days').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
    byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
    hrStart: 9, // 9am
    hrEnd: 22, // 10pm
    trimDates: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'urbanumbrellademo': {
    logo: 'urbanumbrella.png',
    reports: {
      newReports: true,
      logo: 'urbanumbrella/urbanumbrellaLogo.png',
      reportTitle: 'Urban Umbrella',
      reportSubTitle: '',
    },
    companyName: 'Urban Umbrella',
    retailDash: false,
    sameWeather: true,
    calStart: moment('2021-07-19').tz('America/New_York').subtract(52, 'days').startOf('day'),
    calEnd: moment('2021-07-19').tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
    byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
    hrStart: 9, // 9am
    hrEnd: 22, // 10pm
    trimDates: true,
    clientListHide: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'usp': {
    logo: 'usp.png',
    logoSwap: {
      'absre.com': 'absre.png',
    },
    reports: {
      newReports: true,
      logo: 'usp/uspLogo.png',
      reportTitle: 'Union Square',
      reportSubTitle: 'Plaza Traffic',
    },
    companyName: '',
    retailDash: false,
    sameWeather: true,
    calStart: moment().tz('America/New_York').subtract(15, 'days').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(2, 'day').endOf('day'),
    byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
    byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
    hrStart: 7,
    hrEnd: 22,
    trimDates: true,
    vehicleLocations: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
  'va': {
    logo: 'va/va.svg',
    reports: {
      newReports: true,
      logo: 'va/va.svg',
      reportTitle: 'Astor Place',
      reportSubTitle: 'Foot Traffic',
    },
    companyName: '',
    retailDash: false,
    sameWeather: true,
    calStart: moment().tz('America/New_York').subtract(15, 'days').startOf('day'),
    calEnd: moment().tz('America/New_York').subtract(1, 'day').endOf('day'),
    byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
    byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
    hrStart: 0,
    hrEnd: 24,
    trimDates: true,
    categories: {
      'Retail Type': {
        aggregation: 'average',
        types: {
          'Downtown': 'total',
        },
      }
    },
  },
}
